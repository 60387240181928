import React, {useEffect, useContext, useState, useCallback} from 'react';
import {connect, useDispatch} from 'react-redux';
import {LANGUAGES, LanguageContext} from '../language/lang';
import {Address, Div, Hr, IconButton, GiftedChat} from './Components';
import {get} from 'lodash';
import {ICSendChatMessage} from './Icons';
import useDebug from '../debug/useDebug';
import {senderPostMessage, senderReadMessage} from '../redux/senderActions';
import {COLORS} from '../common/constants';
import {courierPostMessage, courierReadMessage} from '../redux/courierActions';
import {courierGetOrder} from '../redux/courierActions';
import {showQuickShowOrder} from '../redux/actions';
import MenuBarNew from './MenuBarNew';
import {hideKeyboard, Platform} from '../common/nativeFunctions';
import Input from './components/Input';
import Button from './components/Button';

function InputToolbar({onSend, width, placeholder, isRTL}) {
  const [text, setText] = useState('');

  const handleClicked = () => {
    if (text) {
      onSend({text});
      setText('');
    }
  };

  return (
    <Div theme="flex-row">
      <Input
        onChange={setText}
        value={text}
        style={{
          width: width - 45,
          height: Platform.OS === 'web' ? 12 : 50,
          ...(Platform.OS === 'web' ? {margin: 0} : {}),
        }}
        placeholder={placeholder}
      />
      <IconButton
        title="sendMessage"
        style={{
          backgroundColor: '#f0f0f0',
          padding: 10,
          borderRadius: 0,
          ...(Platform.OS === 'web' ? {height: 42} : {}),
        }}
        onClick={handleClicked}>
        <ICSendChatMessage
          isRTL={isRTL}
          color={text ? COLORS.mainColorSender : COLORS.disabled}
        />
      </IconButton>
    </Div>
  );
}

function OrderChat({
  navigation,
  chats,
  user,
  orderId = '',
  uid = '',
  order,
  app,
  width,
}) {
  useDebug('OrderChat');
  const lang = useContext(LanguageContext);
  const dispatch = useDispatch();
  const [messages, setMessages] = useState([]);
  useEffect(() => {
    if (orderId && uid) {
      readMessage(orderId, uid);
    }
  }, [uid, orderId]);
  useEffect(() => {
    if (orderId && !order && app === 'courier') {
      dispatch(courierGetOrder(orderId));
    }
  }, [order, orderId]);
  useEffect(() => {
    let allMessages = (chats || []).sort(
      (a, b) => get(b, 'createdAt', 0) - get(a, 'createdAt', 0),
    );
    if (
      !allMessages.some(m => get(m, 'user._id') === 'bambizz-admin') &&
      order.paymentsEnabled !== false
    ) {
      allMessages.push({
        _id: 'bambizz-admin',
        createdAt: 0,
        text: lang[`${app}-chat-warning`],
        user: {
          _id: 'bambizz-admin',
          name: 'Bambizz',
        },
      });
    }
    setMessages(allMessages);
  }, [chats, lang, order.paymentsEnabled]);

  const onSend = useCallback(
    (messages = []) => {
      setMessages(previousMessages => [
        {...messages[0], pending: true},
        ...previousMessages,
      ]);
      postMessage(get(messages, '[0].text', ''), orderId, uid);
    },
    [uid, orderId],
  );

  const onAddressChange = useCallback(() => {
    if (Platform.OS === 'ios') {
      hideKeyboard();
    }
    if (app === 'courier' && !get(order, 'courierUid', '')) {
      dispatch(showQuickShowOrder(get(order, 'id', '')));
    } else {
      navigation.navigate(app + '-order-view', {
        id: get(order, 'id', ''),
      });
    }
  }, [app, order]);
  const addressLang = lang.id?.includes('EN') ? 'EN' : lang.id;

  return (
    <Div style={{height: '100%'}}>
      <MenuBarNew
        shadow
        title={lang.order + ' ' + (order.id || '')}
        onNavigate={() => navigation.goBack()}
      />
      {get(order, 'toAddress.googleId') ? (
        <>
          <IconButton title="returnToOrder" onClick={onAddressChange}>
            <Address
              toAddress={get(order, addressLang + '.toAddress', {})}
              fromAddress={get(order, addressLang + '.fromAddress', {})}
            />
          </IconButton>
          <Hr style={{marginBottom: 0, marginTop: 0}} />
        </>
      ) : null}
      <GiftedChat
        renderInputToolbar={({onSend}) => (
          <InputToolbar
            isRTL={lang.id === LANGUAGES.HE}
            onSend={onSend}
            width={width}
            placeholder={lang['type-chat']}
          />
        )}
        messages={messages}
        onSend={messages => onSend(messages)}
        user={{
          _id: user.uid,
        }}
      />
    </Div>
  );

  function postMessage(text, orderId, uid) {
    if (app === 'sender') {
      dispatch(
        senderPostMessage({
          text,
          orderId,
          courierUid: uid,
        }),
      );
    } else {
      dispatch(
        courierPostMessage({
          text,
          orderId,
          senderUid: uid,
        }),
      );
    }
  }

  function readMessage(orderId, uid) {
    if (app === 'sender') {
      dispatch(senderReadMessage({orderId, courierUid: uid}));
    } else {
      dispatch(courierReadMessage({orderId, senderUid: uid}));
    }
  }
}

const mapStateToProps = (state, ownProps) => {
  let {orderId, uid} = get(ownProps, 'route.params', '');

  orderId = orderId || get(ownProps, 'orderId', '');
  uid = uid || get(ownProps, 'uid', '');

  const chatsObj = get(state, `chats.${orderId}.${uid}.messages`, {});
  const chats = Object.values(chatsObj).filter(obj => typeof obj === 'object');
  const newMessages = chatsObj.newMessages;
  let orders =
    state.app === 'sender'
      ? get(state, 'senderApp.orders', []) || []
      : get(state, 'receiverApp.orders', []) || [];
  orders = [
    ...orders,
    ...(get(state, 'receiverApp.confirmedOrders', []) || []),
  ];

  return {
    chats,
    uid,
    orderId,
    newMessages,
    order: orders.find(order => order.id === orderId) || {},
    user: state.user,
    offers: get(state, 'senderApp.offers', []) || [],
    offline: state.offline,
    width: get(state, 'window.width', 0),
    height: get(state, 'window.height', 0),
    userInProgress: state.userInProgress,
    app: state.app,
  };
};

export default connect(mapStateToProps)(OrderChat);
