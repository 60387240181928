import React, {useContext, useEffect, useRef} from 'react';
import {connect} from 'react-redux';
import {setLanguage, reloadToApp} from '../../redux/actions';
import {LanguageContext, getSystemLang} from '../../language/lang';
import {get} from 'lodash';
import {
  Title,
  IconButton,
  Div,
  Bold,
  Loader,
  Span,
  Hr,
  CheckBox,
} from '../Components';
import {
  ICForm,
  ICSettings,
  ICClose,
  ICQuestion,
  ICMyOrders,
  ICPoints,
  ICBilling,
  ICContactUs,
  ICSender,
  ICNotifications,
  ICUpdateApp,
  ICWhatsApp,
  ICChats,
  ICDiscounts,
} from '../Icons';
import {COLORS, CONSTANTS} from '../../common/constants';
import useDebug from '../../debug/useDebug';
import Analytics from '../../common/analytics';
import {openInStore, openUrl, Platform} from '../../common/nativeFunctions';
import {useFocusOnRender} from '../hooks/useFocusOnRender';
import {useOnEsc} from '../hooks/useOnEsc';
import {useId} from '../hooks/useId';

const MyOrdersButtonComponent = ({ordersWithAction, direction, navigate}) => {
  return (
    <IconButton
      role="menuitem"
      title="my-orders"
      labelledBy="my-orders-title-id"
      style={styles.menuButton}
      onClick={() => navigate('sender-my-orders')}>
      <Div theme="flex-row ai-center" noDirectionEdit style={direction}>
        <ICMyOrders width={60} height={20} />
        <Div
          theme="flex-row ai-center jc-space-between flex-1"
          noDirectionEdit
          style={direction}>
          <Span
            style={styles.menuTitle}
            isButtonMainText
            id="my-orders-title-id">
            my-orders
          </Span>
          {ordersWithAction ? (
            <Span
              style={{color: '#27AE60', fontSize: 18, marginHorizontal: 10}}
              noTranslate>
              {ordersWithAction}
            </Span>
          ) : null}
        </Div>
      </Div>
    </IconButton>
  );
};

const MyOrdersButton = connect(state => ({
  ordersWithAction: (get(state, 'senderApp.orders', []) || []).filter(
    o => o.status === 'OFFER_ACCEPTED',
  ).length,
}))(MyOrdersButtonComponent);

function MenuBarDrawer({
  navigationRef,
  user,
  height,
  userInProgress,
  buildNum,
  setAppHandler,
  notifications,
  whatsAppLink,
  newMessages,
  paymentsEnabled,
}) {
  useDebug('MenuBarDrawer');
  const lang = useContext(LanguageContext);
  const menuRef = useFocusOnRender();

  let direction =
    getSystemLang() === 'HE'
      ? {flexDirection: 'row-reverse'}
      : {flexDirection: 'row'};
  direction.marginVertical = 1.5;
  let pointsLevel = 'copper';
  if (get(user, 'senderPoints', 0) > 1000) {
    pointsLevel = 'silver';
  }
  if (get(user, 'senderPoints', 0) > 5000) {
    pointsLevel = 'gold';
  }

  const navigate = screen => {
    navigationRef.current?.closeDrawer();
    setTimeout(() => navigationRef.current?.navigate(screen), 1);
  };

  useOnEsc({
    onEscPressed: () => navigationRef.current?.closeDrawer(),
  });

  const MENU_OPTIONS = [
    {
      show: CONSTANTS.buildNum < buildNum,
      icon: <ICUpdateApp width={25} height={25} color={COLORS.red} />,
      text: (
        <Span style={{...styles.menuTitle, color: COLORS.red}}>
          update-app-short
        </Span>
      ),
      title: 'update-app-short',
      action: () =>
        openInStore({
          appName: 'Bambizz',
          appStoreId: 'caom.bambizz.app',
          playStoreId: 'com.bambizz',
        }).catch(err => {
          Analytics.reportError(err);
        }),
    },
    {
      show: true,
      icon: <ICSender width={20} height={25} />,
      text: 'send-package-now',
      title: 'send-package-now',
      action: () => navigate('sender-input-order-1'),
    },
    {
      show: true,
      component: <MyOrdersButton direction={direction} navigate={navigate} />,
    },
    {
      show: true,
      icon: <ICNotifications width={25} height={25} showDot={notifications} />,
      text: 'notifications',
      title: 'notifications',
      action: () => navigate('sender-notifications'),
    },
    {
      show: true,
      icon: <ICChats width={25} height={20} showDot={newMessages} />,
      text: 'chats',
      title: 'chats',
      action: () => navigate('sender-chat-list'),
    },
    {
      show: paymentsEnabled !== false,
      icon: <ICBilling width={25} height={25} />,
      text: 'billing',
      title: 'billing',
      action: () => navigate('sender-billing'),
    },
    {
      show: true,
      icon: <ICContactUs width={25} height={25} />,
      text: 'contact-us',
      title: 'contact-us',
      action: () => navigate('sender-contact-us'),
    },
    {
      show: whatsAppLink,
      icon: <ICWhatsApp width={25} height={25} />,
      text: 'need-help-qs',
      title: 'need-help-qs',
      action: () => openUrl(whatsAppLink),
    },
    {
      show: true,
      icon: <ICQuestion width={25} height={25} />,
      text: 'about-us',
      title: 'about-us',
      action: () => navigate('sender-about-us'),
    },
    {
      show: true,
      icon: <ICSettings width={25} height={25} />,
      text: 'settings',
      title: 'settings',
      action: () => navigate('settings'),
    },
    {
      show: true,
      icon: <ICForm width={25} height={25} />,
      text: 'terms-and-conditions',
      title: 'terms-and-conditions',
      action: () => navigate('sender-terms-and-conditions'),
    },
  ];
  return (
    <Div style={{height}} focusTrap renderAsHtml="nav">
      <Div
        theme="flex-row jc-space-between ai-center"
        noDirectionEdit
        style={direction}>
        {get(user, 'uid', '') ? (
          <IconButton
            title={lang.hi + ', ' + get(user, 'name', '')}
            theme="purple empty"
            style={{margin: 0, paddingBottom: 0, paddingTop: 0}}
            labelledBy="username-title-id"
            onClick={() => navigate('settings')}>
            <Title
              noOverflow
              noTranslate
              style={styles.title}
              isButtonMainText
              id="username-title-id"
              numberOfLines={1}>
              {lang.hi + ', ' + get(user, 'name', '')}
            </Title>
          </IconButton>
        ) : userInProgress ? (
          <Loader />
        ) : (
          <Div theme="flex-row jc-center flex-1 ai-center">
            <IconButton
              title="login"
              theme="purple empty"
              style={{margin: 0, padding: 0}}
              labelledBy="menubar-login-title-id"
              onClick={() => navigate('sender-login')}>
              <Bold
                isButtonMainText
                id="menubar-login-title-id"
                style={{
                  fontSize: 18,
                  color: COLORS.mainColorSender,
                  paddingBottom: 10,
                  paddingTop: 10,
                }}>
                login
              </Bold>
            </IconButton>
          </Div>
        )}
        <IconButton
          title="close"
          style={styles.close}
          onClick={() => navigationRef.current?.closeDrawer()}>
          <ICClose color={COLORS.mainColorSender} width={45} height={25} />
        </IconButton>
      </Div>
      <IconButton
        title="points"
        labelledBy="menubar-point-title-id"
        style={{
          ...direction,
          ...styles.pointButton,
        }}
        onClick={() => navigate('sender-points-overview')}>
        <Div
          theme="flex-row jc-space-between ai-center"
          noDirectionEdit
          style={{
            ...direction,
            width: '100%',
          }}>
          <Div
            theme="flex-row jc-space-between ai-center"
            noDirectionEdit
            style={{
              ...direction,
            }}>
            <Bold
              isButtonMainText
              id="menubar-point-title-id"
              style={{
                fontSize: 18,
                color: COLORS.mainColorSender,
                paddingBottom: 5,
                paddingTop: 5,
              }}>
              points
            </Bold>
            <Bold
              style={{
                fontSize: 18,
                marginHorizontal: 10,
                color: COLORS.mainColorSender,
              }}
              noTranslate>
              {String(get(user, 'senderPoints', 0))}
            </Bold>
          </Div>
          <ICPoints type={pointsLevel} />
        </Div>
      </IconButton>
      <Hr style={styles.mainLine} />
      <Div
        style={{
          height: height - (Platform.OS === 'web' ? 50 : 159),
          backgroundColor: '#fff',
        }}
        role="menu"
        ref={menuRef}
        scrollY>
        {MENU_OPTIONS.filter(option => option.show).map((option, i) => (
          <React.Fragment key={i}>
            {i !== 0 ? <Hr style={styles.lineStyle} /> : null}
            {option.component ? (
              option.component
            ) : (
              <IconButton
                title={option.title}
                style={styles.menuButton}
                role="menuitem"
                labelledBy={`menubar-item-id-${i}`}
                onClick={option.action}>
                <Div
                  theme="flex-row ai-center"
                  noDirectionEdit
                  style={direction}>
                  <Div theme="flex-row ai-center jc-center" style={{width: 60}}>
                    {option.icon}
                  </Div>
                  <Span
                    style={styles.menuTitle}
                    isButtonMainText
                    id={`menubar-item-id-${i}`}>
                    {option.text}
                  </Span>
                </Div>
              </IconButton>
            )}
          </React.Fragment>
        ))}
      </Div>
      {Platform.OS !== 'web' ? (
        <IconButton
          title="switch-courier-app"
          style={{
            ...styles.menuButton,
            backgroundColor: '#FFDAC8',
            width: '100%',
            paddingTop: 5,
            paddingBottom: 5,
            paddingLeft: 0,
            paddingRight: 0,
            padding: 0,
          }}
          labelledBy="switch-courier-app-title"
          onClick={() => {
            navigationRef.current?.closeDrawer();
            setAppHandler('courier');
          }}>
          <Div theme="flex-row ai-center" noDirectionEdit style={direction}>
            <Div
              theme="flex-row ai-center jc-center"
              noDirectionEdit
              style={direction}>
              <Div style={{width: 10}} />
              <CheckBox
                ariaHidden
                type="toggle"
                onChange={() => {
                  navigationRef.current?.closeDrawer();
                  setAppHandler('courier');
                }}
              />
              <Span
                isButtonMainText
                id="switch-courier-app-title"
                style={styles.menuTitle}>
                switch-courier-app
              </Span>
            </Div>
          </Div>
        </IconButton>
      ) : null}
    </Div>
  );
}

const styles = {
  pointButton: {
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    padding: 0,
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: 10,
    paddingRight: 10,
  },
  close: {
    marginHorizontal: 0,
    padding: 5,
  },
  drawerBG: {
    backgroundColor: '#FCFCFC',
  },
  hr: {
    borderBottomColor: '#e4e4e4',
    marginHorizontal: 20,
  },
  menuButton: {
    minWidth: 120,
    paddingBottom: 2,
    paddingTop: 2,
    paddingLeft: 0,
    paddingRight: 0,
  },
  lineStyle: {
    margin: 0,
    borderBottomColor: '#F2F2F2',
    marginLeft: 10,
    marginRight: 10,
    marginTop: 5,
    marginBottom: 5,
  },
  mainLine: {
    margin: 0,
    borderBottomColor: COLORS.mainColorSender,
    marginLeft: 10,
    marginRight: 10,
    marginTop: 10,
    marginBottom: 10,
  },
  menuTitle: {
    color: COLORS.black,
    fontSize: 16,
    lineHeight: 22,
    marginHorizontal: 5,
    paddingBottom: Platform.OS !== 'web' ? 10 : 5,
    paddingTop: Platform.OS !== 'web' ? 10 : 5,
  },
  title: {
    paddingBottom: 10,
    paddingTop: 10,
    lineHeight: 20,
    marginHorizontal: 0,
    maxWidth: 180,
    color: COLORS.mainColorSender,
  },
  titleContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  newMessagesDot: {
    position: 'absolute',
    ...(Platform.OS !== 'web' ? {top: -5} : {}),
    marginBottom: 15,
    marginLeft: 10,
    marginRight: 10,
    backgroundColor: COLORS.red,
    borderRadius: 30,
    width: 15,
    height: 15,
    elevation: 2,
  },
};

const mapStateToProps = state => {
  let app = state.app;
  let notifications = (state.notifications || []).some(n => n.section === app);
  const newMessages = Object.entries(get(state, 'chats', {}))
    .map(ent => {
      const newMessages = get(
        Object.values(ent[1] || {}),
        '[0].messages.newMessages',
        false,
      );
      const type = get(Object.values(ent[1] || {}), '[0].type', '');

      return {newMessages, type};
    })
    .filter(chat => chat.type === app)
    .some(m => m.newMessages);
  return {
    user: state.user,
    newMessages,
    notifications,
    offline: state.offline,
    userInProgress: state.userInProgress,
    height: get(state, 'window.height', 0),
    whatsAppLink: get(state, 'serverConstants.whatsAppLink', ''),
    buildNum: get(
      state,
      'serverConstants.warnBuildNum' + Platform.OS.toUpperCase(),
      0,
    ),
    paymentsEnabled: get(state, 'serverConstants.paymentsEnabled', null),
  };
};

const mapDispatchToProps = dispatch => ({
  setLanguage: lang => dispatch(setLanguage(lang)),
  setAppHandler: app => dispatch(reloadToApp(app)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MenuBarDrawer);
