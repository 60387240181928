import React, {useState} from 'react';
import {connect} from 'react-redux';
import {Div, IconButton} from './Components';
import {get} from 'lodash';
import {ICWhatsApp} from './Icons';
import {openUrl, Platform} from '../common/nativeFunctions';
import FAQComponent from './FAQComponent';
import Input from './components/Input';
import MenuBarNew from './MenuBarNew';

function FAQPage({whatsAppLink, width}) {
  const [search, setsearch] = useState('');
  return (
    <Div style={{height: '100%', overflow: 'hidden'}}>
      <MenuBarNew shadow title="faq-title" />
      <Div scrollY>
        <Input
          style={{
            margin: 10,
            width: width - (Platform.OS === 'web' ? 55 : 20),
          }}
          value={search}
          onChange={val => setsearch(val)}
          placeholder="search-faq"
        />
        <Div style={{padding: 10}}>
          <FAQComponent search={search} />
        </Div>
        {whatsAppLink ? (
          <Div style={{position: 'absolute', bottom: 140, left: 10}}>
            <IconButton
              theme="flex-row ai-center"
              onClick={() => openUrl(whatsAppLink)}
              title="whatsapp"
              style={{
                borderRadius: 40,
                padding: 9,
                backgroundColor: '#00E676',
              }}>
              <ICWhatsApp width={40} height={40} />
            </IconButton>
          </Div>
        ) : null}
      </Div>
    </Div>
  );
}

const mapStateToProps = state => ({
  user: state.user,
  offline: state.offline,
  app: state.app,
  height: get(state, 'window.height', 0),
  width: get(state, 'window.width', 0),
  whatsAppLink: get(state, 'serverConstants.whatsAppLink', ''),
});

export default connect(mapStateToProps)(FAQPage);
