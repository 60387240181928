import React, {useContext} from 'react';
import {get} from 'lodash';
import {LanguageContext} from '../../language/lang';
import {COLORS} from '../../common/constants';
import Span from './Span';

export default ({
  children,
  theme,
  noTranslate,
  style,
  textStyle,
  onClick,
  disabled,
}) => {
  const lang = useContext(LanguageContext);
  let color = '#000000';
  switch ((theme || '').split(' ')[0] || '') {
    case 'orange':
      color = COLORS.mainColorCourier;
      break;
    case 'purple':
      color = COLORS.mainColorSender;
      break;
    case 'green':
      color = COLORS.covidMainColor;
      break;
  }
  let fill = get((theme || '').split(' '), '[1]', '');
  let text = children || '';
  if (!noTranslate && lang[text]) {
    text = lang[text];
  }
  let css = {
    border: 'none',
    background: 'none',
    display: 'block',
    width: '100%',
    backgroundColor: disabled ? '#aaaaaa' : color,
    borderRadius: 5,
    padding: 15,
    cursor: 'pointer',
    ...(fill === 'empty'
      ? {
          backgroundColor: 'transparent',
          borderStyle: 'solid',
          borderColor: disabled ? '#aaaaaa' : color,
          borderWidth: 3,
          padding: 12,
        }
      : {}),
    ...style,
  };
  for (let key in css) {
    if (key === 'marginHorizontal') {
      css.marginLeft = css[key];
      css.marginRight = css[key];
    }
    if (key === 'marginVertical') {
      css.marginTop = css[key];
      css.marginBottom = css[key];
    }
    if (key === 'paddingHorizontal') {
      css.paddingLeft = css[key];
      css.paddingRight = css[key];
    }
    if (key === 'paddingVertical') {
      css.paddingTop = css[key];
      css.paddingBottom = css[key];
    }
  }
  for (let key in css) {
    if (typeof css[key] === 'number') {
      css[key] = css[key] + 'px';
    }
  }
  if (
    Object.keys(css).some(
      key => typeof key === 'string' && key.indexOf('flex') !== -1,
    )
  ) {
    css.display = 'flex';
  }
  return (
    <button
      aria-label={text}
      title={text}
      onClick={() =>
        typeof onClick === 'function' && !disabled ? onClick() : null
      }
      disabled={disabled}
      type="button"
      style={css}>
      <Span
        style={{
          color: 'white',
          fontSize: 18,
          textAlign: 'center',
          ...(fill === 'empty'
            ? {
                color: disabled ? '#aaaaaa' : color,
              }
            : {}),
          ...textStyle,
        }}>
        {text}
      </Span>
    </button>
  );
};
