import React, {useContext, useEffect, useState} from 'react';
import {COLORS} from '../../common/constants';
import {LanguageContext, LANGUAGES, flipDirection} from '../../language/lang';
import {ThemeContext} from '../../themes/theme';

export default React.forwardRef(
  (
    {
      style,
      value,
      onChange,
      onClick,
      error,
      errorMessage,
      disabled,
      onFocus,
      type,
      theme,
      placeholder,
      onBecameEditable,
      onEnterPress,
      onBlur,
      ...props
    },
    ref,
  ) => {
    const themeContext = useContext(ThemeContext);
    const lang = useContext(LanguageContext);
    const [focus, setfocus] = useState(false);
    let styles = {
      backgroundColor: disabled ? '#f7f6f2' : '#f0f0f0',
      borderColor: error ? 'red' : '#f0f0f0',
      borderRadius: 0,
      borderWidth: 1,
      borderStyle: 'solid',
      color: COLORS.black,
      fontSize: 17,
      padding: '14px 16px',
      lineHeight: 23,
      textAlign: lang.id.includes(LANGUAGES.EN) ? 'left' : 'right',
      width: 'calc(100% - 34px)',
      margin: '5px 0px',
      ...style,
    };
    (theme || '').split(' ').forEach(t => {
      if (themeContext[t]) {
        styles = {...styles, ...themeContext[t]};
      }
    });
    if (styles.flexDirection && flipDirection(lang.id)) {
      if (styles.flexDirection === 'row') {
        styles.flexDirection = 'row-reverse';
      } else if (styles.flexDirection === 'row-reverse') {
        styles.flexDirection = 'row';
      }
    }
    let css = styles;
    for (let key in css) {
      if (typeof css[key] === 'number') {
        css[key] = css[key] + 'px';
      }
    }
    useEffect(() => {
      if (typeof onBecameEditable === 'function') {
        onBecameEditable();
      }
    }, []);
    useEffect(() => {
      if (focus && typeof onFocus === 'function') {
        onFocus();
      }
    }, [focus]);
    if (type === 'phone') {
      type = 'tel';
    }

    return (
      <input
        {...props}
        aria-label={lang[placeholder] || placeholder || ''}
        title={lang[placeholder] || placeholder || ''}
        className={error ? 'error' : ''}
        aria-invalid={!!error}
        aria-errormessage={
          error ? lang.defaultErrorMessage || errorMessage : null
        }
        ref={ref}
        type={type}
        onChange={e =>
          typeof onChange === 'function' ? onChange(e.target.value) : null
        }
        onClick={() => (typeof onClick === 'function' ? onClick() : null)}
        value={value}
        onFocus={() => setfocus(true)}
        onBlur={() => {
          setfocus(false);
          onBlur?.();
        }}
        disabled={disabled}
        placeholder={lang[placeholder] || placeholder || ''}
        style={css}
        onKeyDown={e => {
          if (e?.key === 'Enter') {
            onEnterPress?.();
          }
        }}
      />
    );
  },
);
