import React, {useEffect, useState} from 'react';

import {useThrottleCallback} from '@react-hook/throttle';
import Div from './components/Div';
import {ngramSimilarity} from '../common/function';
import {ICArrowDown, ICArrowLeft} from './Icons';
import IconButton from './components/IconButton';
import Span from './components/Span';
import {connect} from 'react-redux';
import {get} from 'lodash';
import {COLORS} from '../common/constants';

const senderData = {
  title: 'שאלות נפוצות שולח',
  rows: [
    //sender faq
    {
      title: 'האם אפשר לשלוח כל חבילה עם במביז?',
      content:
        'במביז מציעה אפשרות למשלוח חבילות בגודל 2/5/10 ק"ג, חבילות גדולות יותר אפשר לשלוח תחת הקטגוריה "מותאם אישית"',
    },
    {
      title: 'האם במביז מתחייבים למצוא שליח לכל חבילה שמעלים לאפליקציה?',
      content:
        'במביז שולחים את המשלוח שלך למאות השליחים הרלוונטיים למשלוח שהעליתם לאפליקציה, יתכן שלא תמיד נמצא שליח שמתאים לכם, במקרה שלא מצאנו שליח לחבילה בתוך זמן סביר. החבילה תימחק מהמערכת. כמובן שככל שלא מצאנו שליח מתאים לא תחויבו בעלות כלשהי',
    },
    {
      title: 'האם במביז שולחים בינעירוני או עירוני?',
      content: 'בבמביז אפשר לעלות משלוח לכל מקום בארץ בינעירוני ועירוני.',
    },
    {
      title: 'איזו שעה אפשר לעלות משלוח לאפליקציית במביז?',
      content:
        'אפשר לעלות משלוח לאפליקציית במביז בכל שעה משעות היום והלילה, משלוחים אפשר לעלות 24 שעות, אנחנו ממליצים לעלות את המשלוח בשעות הבוקר, זה מעלה את הסיכוי למצוא שליח.',
    },
    {
      title: 'סיימתי לעלות משלוח והמערכת מחפשת לי שליח מתאים, מה זה אומר?',
      content:
        'לאחר העלאת המשלוח לאפליקציית במביז, אנחנו בבמביז שולחים הודעה לכל השליחים הרלוונטיים למשלוח שלכם. לפעמים לקוח מספר שניות למצוא שליח, לפעמים מספר דקות ולפעמים אף שעות, זאת מערכת אמיתית של שליחים ושלוחים והמחיר המעולה שאתם מקבלים הוא בזכות החופש של השליחים. ככל שיהיו יותר משלוחים בבמביז זמן התגובה של השליחים ישתפר.',
    },
    {
      title: 'איך אפשר לדעת מהו סטטוס החבילה שלי?',
      content:
        'כל חבילה מופיעה בתפריט תחת "החבילות שלי". ישנם חבילות שהם ממש פעילות עכשיו והם יופיעו פעמיים גם תחת "החבילות שלי" וגם תחת קטגוריית "חבילות פעילות".',
    },
    {
      title:
        'העליתי חבילה לאפליקציית במביז וכתוב "מחפשים שליח" האם אפשרי לעלות שוב את החבילה?',
      content:
        'אפשר לעלות חבילה שוב לאחר זמן רב שעובר או כשהחבילה נמחקת מהמערכת, ככל שלא עבר זמן רב אין טעם לעלות חבילה מחדש היות והחבילה נמצאת במערכת וככל שימצא שליח הוא יקח את החבילה.',
    },
    {
      title: 'האם אפשר למחוק חבילה?',
      content:
        'ככל שהשליח לא אישר את החבילה לאיסוף אפשר לבטל את החבילה במקרים שבהם ישנה סיבה מיוחדת לביטול החבילה, ככל שהשליח אישר את החבילה לא ניתן לבטל את החבילה למעט מקרה שהשליח מאשר את ביטול החבילה. ',
    },
    {
      title: 'באיזה מקרה אפשר לבטל חבילה לאחר שהשליח אישר איסוף?',
      content:
        'כשהשליח מאשר חבילה הוא לפעמים כבר בדרך לאסוף את החבילה לכן השליח הוא היחיד שיכול לאשר ביטול החבילה.',
    },
    {
      title: 'מה זה הערה לשליח?',
      content: `יש לכם חבילה שדורשת רכב? יש לכם חבילה מיוחדת כגון עוגת שוקולד?  הזמנים שלכם צריכים להתאים לאיסוף או לפיזור, 
      אל תחסכו במילים, כתבו הכל בהערה לשליח, זה יחסוך לכם כאבי ראש עם שליחים לא מתאימים.
      `,
    },
    {
      title: 'המערכת לא מצאה לכם שליח והשליחות חשובה לכם מה עושים?',
      content:
        'תחשבו על השליח אולי המחיר היה זול מידי, תעלו את המשלוח במחיר גבוה יותר וזה יתן לשליחים הזדמנות כדאית יותר לאיסוף החבילה. בהצלחה.',
    },
  ],
};

const courierData = {
  title: 'שאלות נפוצות שליח',
  rows: [
    {
      title: 'אני רוצה להירשם כשליח מה עלי לעשות?',
      content:
        'שליח יקר כל שעליך לעשות הוא להירשם לאפליקציית במביז. לעקוב אחר ההוראות.',
    },
    {
      title: 'לפעמים אני רואה משלוחים ולפעמים לא איך מגדירים את המשלוחים?',
      content:
        'זה תלוי האם אתם רוצים לקבל משלוחים בכל הארץ או רק באזור שלכם, קחו בחשבון שכשאתם נרשמים למשלוחים רק באזור שלכם אתם מפסידים הרבה משלוחים שהם היו מתאימים לכם בנסיעות מסוימות.  הגדרות- קבל הודעות על חבילות- להפעיל . קבלת הודעות מותאמות אישית- (כלומר רק באזור שלך) להפעיל או לכבות לפי הצורך.',
    },
    {
      title: 'האם לבמביז יש אלגוריתמים שמחלק משלוחים לפי דירוג לקוח ועד?',
      content:
        ' בבמביז אנחנו רוצים לתת ללקוח ולשליח את השירות למשלוח המקסימלי. לכן אנחנו מבצעים אופטימיזציה של הדירוג ועוד פרמטרים של השליח לפני שאנחנו שולחים לו חבילות לאישור.',
    },
    {
      title: 'האם ביטול חבילות גורם לי לראות פחות חבילות?',
      content:
        'כחלק משירות הלקוחות שלנו אנחנו ממפים את השליחים הכי יעילים ועם כמות הביטולים הנמוכה ביותר, לכן כדאי מאוד לא לבטל חבילה למעט אם זה מקרה חירום ממש.',
    },
    {
      title: 'איך מבטלים חבילה?',
      content:
        'בדף החבילה יש אייקון בחלק העליון בצורת פח אשפה, לחיצה עליו תאפשר לבטל את החבילה. חובה להזין סיבה לביטול. ',
    },
  ],
};

const technicalData = {
  title: 'שאלות לגבי תקלות טכניות',
  rows: [
    {
      title: 'לא מצליחים לקבל קוד אימות ברישום לאפליקציה?',
      content:
        'יש לוודא שאין לכם חסימה כלשהי על המכשיר כגון "חסימת הורים" "חסימת הכשר" ועוד. במידה והכל תקין ועדיין לא מקבלים קוד אימות, אפשר לנסות לקבל קוד אימות על ידי כניסה למערכת מהמחשב הביתי או מהאינטרנט בטלפון ולא דרך האפליקציה. בכל אופן צרו קשר ונשמח לטפל בכם אם לא הסתדרתם.',
    },
    {
      title: 'איך אני יודע אם אני באפליקציית שליח או אפליקציית שולח?',
      content:
        'האייקון של אפליקציית שליח ושולח זהה והוא בצבע כתום עם האות B במרכז     האפליקציות עצמם שונות,      אפליקציה כתומה- אפליקציית שליח (השליח  הוא או היא באים לקחת את החבילה)    אפליקציה תכלת – אפליקציית שולח (השולח הוא או היא הם רוצים למסור את החבילה לשליח שימסור אותה בעבורם לנקודה אחרת)  ',
    },
    {
      title: 'איך עוברים מאפליקציית שליח או שולח לאפליקציית שליח או שולח?',
      content:
        'בכל אחת מהאפליקציות  בחלק העליון ישנו ריבוע עם חץ, לחיצה עליו מקפיצה הודעה "האם ברצונכם לעבור ל.." לחצו לאישור ועברו לאפליקציה שתרצו.       ',
    },
  ],
};

const FaqRowComponent = ({row, width, offset = 50}) => {
  const [open, setopen] = useState(false);
  return (
    <Div>
      <IconButton title={row.title} onClick={() => setopen(!open)}>
        <Div theme="flex-row ai-center">
          {open ? <ICArrowDown /> : <ICArrowLeft />}
          <Span style={{width: width - offset, fontSize: 16, lineHeight: 18}}>
            {row.title}
          </Span>
        </Div>
        {open ? <Span>{row.content}</Span> : null}
      </IconButton>
    </Div>
  );
};

const FaqRow = connect(state => ({
  height: get(state, 'window.height', 0),
  width: get(state, 'window.width', 0),
}))(FaqRowComponent);

const Faq = ({data, offset}) => (
  <Div>
    <Span style={{color: COLORS.mainColorSender, fontSize: 18}}>
      {data.title}
    </Span>
    {data.rows.map((row, i) => (
      <FaqRow row={row} key={i} offset={offset} />
    ))}
  </Div>
);

const filterSearch = (search, data, minScore = 0.2) => {
  return {
    ...data,
    rows: data.rows
      .sort((a, b) => {
        const scoreA = Math.max(
          ngramSimilarity(search, a.title),
          ngramSimilarity(search, a.content),
        );
        const scoreB = Math.max(
          ngramSimilarity(search, b.title),
          ngramSimilarity(search, b.content),
        );
        return scoreB - scoreA;
      })
      .filter(
        row =>
          ngramSimilarity(search, row.title) > minScore ||
          ngramSimilarity(search, row.content) > minScore,
      )
      .slice(0, 5),
  };
};

function FAQComponent({search, minScore, showOne, offset}) {
  const [senderQuestions, setsenderQuestions] = useState(senderData);
  const [courierQuestions, setcourierQuestions] = useState(courierData);
  const [technicalQuestions, settechnicalQuestions] = useState(technicalData);
  const [allInOne, setallInOne] = useState({
    title: 'שאלות נפוצות',
    rows: [...senderData.rows, ...courierData.rows, ...technicalData.rows],
  });

  const applySearch = useThrottleCallback(
    () => {
      if (!search || search.length < 3) {
        setsenderQuestions(senderData);
        setcourierQuestions(courierData);
        settechnicalQuestions(technicalData);
        setallInOne({
          title: 'שאלות נפוצות',
          rows: [
            ...senderData.rows,
            ...courierData.rows,
            ...technicalData.rows,
          ],
        });
      } else {
        setsenderQuestions(filterSearch(search, senderData, minScore));
        setcourierQuestions(filterSearch(search, courierData, minScore));
        settechnicalQuestions(filterSearch(search, technicalData, minScore));
        setallInOne(
          filterSearch(
            search,
            {
              title: 'שאלות נפוצות',
              rows: [
                ...senderData.rows,
                ...courierData.rows,
                ...technicalData.rows,
              ],
            },
            minScore,
          ),
        );
      }
    },
    [search, minScore],
    5,
  );
  useEffect(() => {
    applySearch();
  }, [search]);

  return (
    <Div>
      {!showOne && senderQuestions.rows.length ? (
        <Faq data={senderQuestions} offset={offset} />
      ) : null}
      {!showOne && courierQuestions.rows.length ? (
        <Faq data={courierQuestions} offset={offset} />
      ) : null}
      {!showOne && technicalQuestions.rows.length ? (
        <Faq data={technicalQuestions} offset={offset} />
      ) : null}
      {showOne && allInOne.rows.length ? (
        <Faq data={allInOne} offset={offset} />
      ) : null}
    </Div>
  );
}

export default connect(state => ({
  height: get(state, 'window.height', 0),
  width: get(state, 'window.height', 0),
}))(FAQComponent);
