import React, {useContext, useState, useRef} from 'react';
import {connect, useDispatch} from 'react-redux';
import {Div, IconButton, Image, Shadow, Title, WebView} from './Components';
import {LanguageContext, LANGUAGES} from '../language/lang';
import {get} from 'lodash';
import {COLORS} from '../common/constants';
import {ICBackLeft, ICBackRight, ICClose, ICReload, ICDesktop} from './Icons';
import {hideWebView} from '../redux/actions';
import {openUrl} from '../common/nativeFunctions';
import {useId} from './hooks/useId';
//
function WebViewComponent({title, isVisible, url, height, width}) {
  const lang = useContext(LanguageContext);
  const dispatch = useDispatch();
  const [loading, setloading] = useState(true);
  const labelId = useId();

  const webview = useRef();
  const canGoBack = useRef(false);
  const currentUrl = useRef(url);

  let navIcon =
    lang.id === LANGUAGES.EN ? (
      <ICBackLeft width={30} height={40} color={COLORS.mainColorCourier} />
    ) : (
      <ICBackRight width={30} height={40} color={COLORS.mainColorCourier} />
    );

  return isVisible ? (
    <Div
      style={{backgroundColor: 'white', height, width}}
      role="dialog"
      aria-labelledby={labelId}>
      <Div theme="flex-row jc-space-between">
        <IconButton
          title="reload"
          style={{width: 50}}
          onClick={() => {
            try {
              webview.current.reload();
            } catch (e) {}
          }}>
          <ICReload
            width={20}
            style={{padding: 5}}
            height={40}
            color={COLORS.mainColorCourier}
          />
        </IconButton>
        <Title
          id={labelId}
          theme="purple"
          style={{
            lineHeight: 51,
            fontSize: 18,
            color: COLORS.mainColorCourier,
          }}>
          {title}
        </Title>
        <IconButton
          title="openInBrowser"
          style={{width: 50}}
          onClick={() => openUrl(currentUrl.current)}>
          <ICDesktop width={28} height={40} color={COLORS.mainColorCourier} />
        </IconButton>
        <IconButton
          title="close"
          style={{width: 50}}
          onClick={() => dispatch(hideWebView())}>
          <ICClose width={30} height={40} color={COLORS.mainColorCourier} />
        </IconButton>
      </Div>
      <Shadow />
      <WebView
        ref={webview}
        style={{flex: 1}}
        source={{uri: url}}
        onNavigationStateChange={e => {
          canGoBack.current = e.canGoBack;
          currentUrl.current = e.url;
        }}
        onLoadStart={() => setloading(true)}
        onLoadEnd={() => setloading(false)}
      />
    </Div>
  ) : null;

  function goBack() {
    try {
      if (canGoBack.current) {
        webview.current.goBack();
      } else {
        dispatch(hideWebView());
      }
    } catch (e) {
      console.log(e);
    }
  }
}

const mapStateToProps = state => ({
  url: get(state, 'webview.url', ''),
  title: get(state, 'webview.title', ''),
  width: get(state, 'window.width', 0),
  height: get(state, 'window.height', 0),
  modal: state.modal,
  app: state.app,
});

export default connect(mapStateToProps)(WebViewComponent);
