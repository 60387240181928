import React, {useContext, useMemo} from 'react';
import {connect, useDispatch} from 'react-redux';
import {
  Title,
  IconButton,
  Div,
  Bold,
  Span,
  Shadow,
  Loader,
  List,
  Card as ReactCard,
} from '../Components';
import {LanguageContext} from '../../language/lang';
import {get, isEqual} from 'lodash';
import {ICClose, ICChevronRight, ICChevronLeft} from '../Icons';
import moment from 'moment';
import {COLORS} from '../../common/constants';
import ProgressBar from './ProgressBar';
import useDebug from '../../debug/useDebug';
import {senderConfirmPickup} from '../../redux/senderActions';
import {hideSenderOrdersModal} from '../../redux/actions';
import {Platform} from '../../common/nativeFunctions';
import {useId} from '../hooks/useId';

class Card extends React.Component {
  shouldComponentUpdate(nextProps) {
    let item = get(this, 'props.item', {});
    let nextItem = get(nextProps, 'item', {});
    return !isEqual(item, nextItem);
  }
  render() {
    let item = get(this, 'props.item', {});
    return (
      <ReactCard
        style={{
          paddingVertical: 0,
          width: Platform.OS === 'web' ? item.width - 110 : item.width - 70,
        }}>
        <IconButton
          title="showOrder"
          style={{padding: 0, width: item.width - 110}}
          onClick={() => item.onNavigate('sender-order-view', {id: item.id})}>
          <Div theme="flex-row jc-space-between">
            <Span
              style={{
                ...styles.label,
                backgroundColor: item.labelBackground,
                color: item.labelColor,
              }}>
              {item.label}
            </Span>
          </Div>
          <Div
            theme="flex-row jc-space-between"
            style={{marginVertical: 0, marginTop: 15, marginBottom: 7}}>
            <Div
              theme="flex-column jc-center flex-1 ai-center"
              style={{width: item.width / 2 - 40}}>
              <Bold numberOfLines={1}>{item.fromCity}</Bold>
              <Span numberOfLines={1} style={{fontSize: 10, lineHeight: 15}}>
                {item.fromAddress}
              </Span>
            </Div>
            {item.langId === 'HE' ? (
              <ICChevronLeft theme="purple" height={38} />
            ) : (
              <ICChevronRight theme="purple" height={38} />
            )}
            <Div
              theme="flex-column jc-center flex-1 ai-center"
              style={{width: item.width / 2 - 40}}>
              <Bold numberOfLines={1}>{item.toCity}</Bold>
              <Span numberOfLines={1} style={{fontSize: 10, lineHeight: 15}}>
                {item.toAddress}
              </Span>
            </Div>
          </Div>
          <Div style={{marginHorizontal: 30}}>
            <ProgressBar
              expectedDeliverTime={item.expectedDeliverTime}
              senderPickedUpTime={item.senderPickedUpTime}
              expectedPickupTime={item.expectedPickupTime}
              courierAcceptTime={item.courierAcceptTime}
              status={item.status}
              width={item.width - 166}
            />
          </Div>

          <Div theme="flex-row jc-space-between">
            {item.status === 'OFFER_ACCEPTED' ? (
              <Div theme="flex-column">
                <IconButton
                  title="confirm-pickup-sender"
                  theme="purple"
                  style={{
                    paddingVertical: 15,
                    paddingBottom: 15,
                    paddingHorizontal: 5,
                  }}
                  onClick={item.action}>
                  <Span
                    style={{
                      fontSize: 16,
                      fontWeight: 'bold',
                      color: COLORS.mainColorSender,
                    }}>
                    confirm-pickup-sender
                  </Span>
                </IconButton>
              </Div>
            ) : (
              <Div style={{height: 7}} />
            )}
            <Span style={{fontSize: 12, color: '#c8c8c8', marginTop: 15}}>
              {item.nextTime}
            </Span>
          </Div>
        </IconButton>
      </ReactCard>
    );
  }
}
const itemRenderer = ({item}) => <Card item={item} />;

function PackagesModal({offers, navigation, isVisible, orders, width, height}) {
  useDebug('PackagesModal');
  const lang = useContext(LanguageContext);
  const dispatch = useDispatch();
  const labelId = useId();

  const ordersToRender = useMemo(() => {
    let list = [...orders]
      .filter(o => o.status === 'OFFER_ACCEPTED')
      .sort((a, b) => b.createdTime - a.createdTime);
    if (list.length < 20) {
      list = [
        ...list,
        ...orders
          .filter(o => o.status !== 'DELETED' && o.status !== 'OFFER_ACCEPTED')
          .sort((a, b) => b.createdTime - a.createdTime)
          .slice(0, 20 - list.length),
      ];
    }
    return list.map(order => {
      let nextTime = 0;
      let label = '';
      let labelColor = 'grey';
      let labelBackground = '#f4f4f4';
      switch (get(order, 'status')) {
        case 'POSTED':
          nextTime =
            lang['created-on'] +
            ' ' +
            moment(get(order, 'createdTime', '')).format(
              lang['moment-short-datetime'],
            );
          if (
            (offers || []).filter(offer => offer.orderId === order.id).length >
            0
          ) {
            nextTime += ' ' + lang['got-offers'];
          }
          label = lang.POSTED;
          labelBackground = COLORS.mainColorCourier;
          break;
        case 'OFFER_ACCEPTED':
          nextTime =
            lang['expected-package-pickedup'] +
            (moment(get(order, 'expectedPickupTime', '')).isValid()
              ? ' ' +
                moment(get(order, 'expectedPickupTime', '')).format(
                  lang['moment-short-datetime'],
                )
              : '');
          label = lang.OFFER_ACCEPTED;
          labelBackground = COLORS.labelOrange;
          break;
        case 'PACKAGE_PICKEDUP':
          nextTime =
            lang['expected-package-delivered'] +
            (moment(get(order, 'expectedDeliverTime', '')).isValid()
              ? ' ' +
                moment(get(order, 'expectedDeliverTime', '')).format(
                  lang['moment-short-datetime'],
                )
              : '');
          label = lang.PACKAGE_PICKEDUP;
          labelBackground = COLORS.labelGreen;
          break;
        case 'PACKAGE_RECEIVED':
          nextTime =
            lang['package-delivered'] +
            (moment(get(order, 'deliveryTime', '')).isValid()
              ? ' ' +
                moment(get(order, 'deliveryTime', '')).format(
                  lang['moment-short-datetime'],
                )
              : '');
          label = lang.PACKAGE_RECEIVED;
          labelBackground = COLORS.labelBlue;
          break;
        case 'DELETED':
          nextTime = lang['package-deleted'];
          label = lang.DELETED;
          break;
        case 'GOT_PROBLEM':
          nextTime = lang.GOT_PROBLEM;
          label = lang.GOT_PROBLEM;
          labelColor = 'white';
          labelBackground = 'brown';
          break;
      }
      let fromCity =
        get(order, lang.id + '.fromAddress.street', '') ||
        get(order, 'toAddress.city', '');
      let fromAddress =
        get(order, lang.id + '.fromAddress.city', '') ||
        get(order, 'fromAddress.city', '');
      let toCity =
        get(order, lang.id + '.toAddress.street', '') ||
        get(order, 'toAddress.street', '');
      let toAddress =
        get(order, lang.id + '.toAddress.city', '') ||
        get(order, 'toAddress.city', '');
      return {
        id: get(order, 'id', ''),
        renderId: get(order, 'id', ''),
        nextTime,
        label,
        labelColor,
        labelBackground,
        fromCity,
        fromAddress,
        toCity,
        toAddress,
        expectedDeliverTime: get(order, 'expectedDeliverTime', 0),
        senderPickedUpTime: get(order, 'senderPickedUpTime', 0),
        expectedPickupTime: get(order, 'expectedPickupTime', 0),
        courierAcceptTime: get(order, 'courierAcceptTime', 0),
        status: get(order, 'status', ''),
        width,
        langId: lang.id,
        onNavigate,
        action: () =>
          dispatch(senderConfirmPickup({orderId: get(order, 'id', '')})),
      };
    });
  }, [orders]);
  return isVisible ? (
    <Div style={styles.background} role="dialog" aria-labelledby={labelId}>
      <Div
        style={{...styles.foreground, width: width - 30, height: height - 150}}>
        <Div theme="flex-row jc-space-between">
          <IconButton
            title="close"
            onClick={() => dispatch(hideSenderOrdersModal())}>
            <ICClose color={COLORS.mainColorSender} width={30} height={40} />
          </IconButton>
          <Title
            isButtonMainText
            id={labelId}
            theme="purple"
            style={styles.title}>
            active-packages
          </Title>
          <Div style={{width: 50}} />
        </Div>
        <Shadow />
        <Div>
          {ordersToRender.length === 0 ? (
            <Div theme="flex-column jc-center" style={{height: 200}}>
              <Loader />
            </Div>
          ) : null}
          <List
            itemsToRender={ordersToRender || []}
            itemRenderer={itemRenderer}
            offset={270}
            rowHeight={({index}) =>
              (ordersToRender || [])[index].status === 'OFFER_ACCEPTED'
                ? 140
                : 125
            }
          />
        </Div>
        <Shadow bottom />
        <IconButton
          title="show-more"
          onClick={() => onNavigate('sender-my-orders')}
          style={{width: width - 30}}>
          <Title theme="purple" style={{fontSize: 18}}>
            show-more
          </Title>
        </IconButton>
      </Div>
    </Div>
  ) : null;
  function onNavigate(...params) {
    try {
      navigation.navigate(...params);
      dispatch(hideSenderOrdersModal());
    } catch (e) {}
  }
}

const styles = {
  background: {
    alignItems: 'center',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'space-around',
    width: '100%',
  },

  foreground: {
    backgroundColor: 'white',
    borderRadius: 5,
    paddingBottom: 0,
  },
  label: {
    backgroundColor: '#f4f4f4',
    borderBottomLeftRadius: 5,
    borderBottomRightRadius: 5,
    borderColor: '#fff',
    borderTopWidth: 0,
    borderWidth: 0,
    color: 'grey',
    fontSize: 12,
    lineHeight: 22,
    paddingHorizontal: 3,
    textAlign: 'center',
    width: 150,
  },
  list: height => ({
    height: height - 270,
  }),
  title: {
    fontSize: 18,
    lineHeight: 52,
  },
};

const mapStateToProps = state => ({
  width: get(state, 'window.width', 0),
  navigation: get(state, 'navigation', {}),
  height: get(state, 'window.height', 0),
  orders: get(state, 'senderApp.orders', []) || [],
  ordersModal: get(state, 'senderApp.ordersModal', false),
  offers: get(state, 'senderApp.offers', []) || [],
});

export default connect(mapStateToProps)(PackagesModal);
