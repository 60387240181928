import React, {useContext} from 'react';
import {LanguageContext} from '../../language/lang';
import {ThemeContext} from '../../themes/theme';

export default ({
  children,
  theme,
  translate,
  onClick,
  disabled,
  style,
  title,
  labelledBy,
  ...props
}) => {
  const lang = useContext(LanguageContext);
  const themeContext = useContext(ThemeContext);

  let text = children || '';
  if (translate) {
    text = lang[text];
  }
  let styles = {
    padding: 10,
    border: 'none',
    background: 'none',
    margin: 0,
    display: 'block',
    cursor: 'pointer',
    ...(style || {}),
  };
  (theme || '').split(' ').forEach(t => {
    if (themeContext[t]) {
      styles = {...styles, ...themeContext[t]};
    }
  });
  let css = styles;
  for (let key in css) {
    if (key === 'marginHorizontal') {
      css.marginLeft = css[key];
      css.marginRight = css[key];
    }
    if (key === 'marginVertical') {
      css.marginTop = css[key];
      css.marginBottom = css[key];
    }
    if (key === 'paddingHorizontal') {
      css.paddingLeft = css[key];
      css.paddingRight = css[key];
    }
    if (key === 'paddingVertical') {
      css.paddingTop = css[key];
      css.paddingBottom = css[key];
    }
  }
  for (let key in css) {
    if (typeof css[key] === 'number') {
      css[key] = css[key] + 'px';
    }
  }
  if (
    Object.keys(css).some(
      key => typeof key === 'string' && key.indexOf('flex') !== -1,
    )
  ) {
    css.display = 'flex';
  }
  if (
    Object.keys(css).some(
      key => typeof key === 'string' && key.indexOf('elevation') !== -1,
    )
  ) {
    css.boxShadow = 'rgb(216 216 216) 0px 0px 4px 0px';
  }

  let ariaLabel = lang[title] || title;

  if (!ariaLabel && typeof text === 'string') {
    ariaLabel = text;
  }

  if (!ariaLabel) {
    console.warn('The following IconButton is missing aria-label', text);
  }

  return (
    <button
      {...props}
      aria-label={!labelledBy ? ariaLabel : undefined}
      aria-labelledby={labelledBy}
      title={ariaLabel}
      type="button"
      disabled={disabled}
      onClick={() => (typeof onClick === 'function' ? onClick() : null)}
      style={styles}>
      {text}
    </button>
  );
};
