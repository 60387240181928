import React, {useContext, useRef, useState} from 'react';
import {COLORS} from '../../common/constants';
import {LanguageContext, LANGUAGES} from '../../language/lang';

export default React.forwardRef(
  ({style, value, onChange, placeholder, error, errorMessage}, ref) => {
    const lang = useContext(LanguageContext);
    const heightRef = useRef('24px');
    const [, setfocus] = useState(false);
    const styles = {
      color: COLORS.black,
      border: 'none',
      fontSize: 16,
      padding: 16,
      backgroundColor: '#f0f0f0',
      lineHeight: 24,
      textAlign: lang.id.includes(LANGUAGES.EN) ? 'left' : 'right',
      width: 'calc(100% - 30px)',
      margin: '7px 0',
      height: heightRef.current,
      ...style,
    };
    let css = styles;
    for (let key in css) {
      if (typeof css[key] === 'number') {
        css[key] = css[key] + 'px';
      }
    }
    return (
      <textarea
        aria-invalid={!!error}
        aria-errormessage={
          error ? lang.defaultErrorMessage || errorMessage : null
        }
        aria-label={lang[placeholder] || placeholder || ''}
        title={lang[placeholder] || placeholder || ''}
        className={error ? 'error' : ''}
        ref={ref}
        onChange={e => {
          if (typeof onChange === 'function') {
            onChange(e.target.value);
          }
          e.target.style.height = '0px';
          const computed = window.getComputedStyle(e.target);
          const height =
            parseInt(computed.getPropertyValue('border-top-width'), 10) +
            parseInt(computed.getPropertyValue('padding-top'), 10) +
            e.target.scrollHeight +
            parseInt(computed.getPropertyValue('padding-bottom'), 10) +
            parseInt(computed.getPropertyValue('border-bottom-width'), 10);
          e.target.style.height = height - 64 + 'px';
          heightRef.current = height - 64 + 'px';
        }}
        value={value}
        placeholder={lang[placeholder] || placeholder || ''}
        onFocus={() => setfocus(true)}
        onBlur={() => setfocus(false)}
        style={css}
      />
    );
  },
);
