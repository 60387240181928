import React, {useContext, useEffect, useMemo, useState} from 'react';
import {connect} from 'react-redux';
import {COLORS} from '../../common/constants';
import {DateTime} from 'luxon';
import {getSystemLang, LanguageContext, LANGUAGES} from '../../language/lang';
import Div from './Div';
import {ICArrowDown, ICArrowUp} from '../Icons';
import IconButton from './IconButton';
import Span from './Span';
import {Platform} from '../../common/nativeFunctions';

const DateTimePickerComponent = ({app, minDate, maxDate, onChange}) => {
  const lang = useContext(LanguageContext);
  const minDateTime = DateTime.fromMillis(minDate);
  const maxDateTime = DateTime.fromMillis(maxDate);
  const now = DateTime.fromMillis(Date.now());
  const [selectedHour, setselectedHour] = useState(minDateTime.hour);
  const [selectedMinute, setselectedMinute] = useState(
    Math.min(Math.ceil(minDateTime.minute / 10) * 10, 50),
  );
  const [selectedDayIndex, setselectedDayIndex] = useState(0);
  const daysRange = useMemo(() => {
    const days = [];
    let temp = minDateTime;
    days.push({
      minHour: minDateTime.hour,
      maxHour: 23,
      date: temp,
    });
    temp = temp.plus({days: 1});
    while (temp.startOf('day') < maxDateTime.startOf('day')) {
      days.push({
        minHour: 0,
        maxHour: 23,
        date: temp,
      });
      temp = temp.plus({days: 1});
    }
    days.push({
      minHour: 0,
      maxHour: maxDateTime.hour,
      date: temp,
    });
    return days.slice(0, 5).map(d => ({...d, desc: getDateDesc(d.date)}));
  }, [minDate, maxDate]);
  useEffect(() => {
    if (typeof onChange === 'function') {
      let date = daysRange[selectedDayIndex].date;
      date = date.set({hour: selectedHour});
      date = date.set({minute: selectedMinute});
      onChange(date.toMillis());
    }
  }, [selectedMinute, selectedHour, selectedDayIndex, onChange]);
  const mainColor =
    app === 'sender' ? COLORS.mainColorSender : COLORS.mainColorCourier;
  const smallTitle = useMemo(() => {
    if (
      +now.startOf('day') === +daysRange[selectedDayIndex].date.startOf('day')
    ) {
      return lang.today;
    }
    if (
      +now.plus({days: 1}).startOf('day') ===
      +daysRange[selectedDayIndex].date.startOf('day')
    ) {
      return lang.tomorrow;
    }
    return lang[daysRange[selectedDayIndex].date.weekday];
  }, [selectedDayIndex, daysRange, lang]);

  return (
    <Div>
      <Div theme="flex-column ai-center">
        <IconButton title="addDay" onClick={onDayUp}>
          <ICArrowUp
            width={50}
            height={50}
            color={
              selectedDayIndex < daysRange.length - 1 ? mainColor : 'lightgrey'
            }
          />
        </IconButton>
        <Span style={{fontSize: 28, lineHeight: 28, fontWeight: '600'}}>
          {daysRange[selectedDayIndex].desc}
        </Span>
        <Span>{smallTitle}</Span>
        <IconButton title="subDay" onClick={onDayDown}>
          <ICArrowDown
            width={50}
            height={50}
            color={selectedDayIndex > 0 ? mainColor : 'lightgrey'}
          />
        </IconButton>
      </Div>
      <Div
        theme="jc-center ai-center"
        noDirectionEdit
        style={{
          flexDirection:
            Platform.OS === 'web'
              ? lang.id === LANGUAGES.HE
                ? 'row-reverse'
                : 'row'
              : getSystemLang() === LANGUAGES.HE
              ? 'row-reverse'
              : 'row',
        }}>
        <Div theme="flex-column ai-center">
          <IconButton title="addHour" onClick={onHourUp}>
            <ICArrowUp width={50} height={50} color={mainColor} />
          </IconButton>

          <Span noTranslate style={{fontSize: 24, fontWeight: '600'}}>
            {twoDigits(selectedHour)}
          </Span>
          <IconButton title="subHour" onClick={onHourDown}>
            <ICArrowDown width={50} height={50} color={mainColor} />
          </IconButton>
        </Div>
        <Span style={{fontSize: 24, fontWeight: '600'}}>:</Span>
        <Div theme="flex-column ai-center">
          <IconButton title="addMinute" onClick={onMinuteUp}>
            <ICArrowUp width={50} height={50} color={mainColor} />
          </IconButton>

          <Span noTranslate style={{fontSize: 24, fontWeight: '600'}}>
            {twoDigits(selectedMinute)}
          </Span>
          <IconButton title="subMinute" onClick={onMinuteDown}>
            <ICArrowDown width={50} height={50} color={mainColor} />
          </IconButton>
        </Div>
      </Div>
    </Div>
  );
  function onDayUp() {
    let index = Math.min(selectedDayIndex + 1, daysRange.length - 1);
    const maxHour = daysRange[index].maxHour;
    const minHour = daysRange[index].minHour;
    if (selectedHour < minHour) {
      setselectedHour(daysRange[index].minHour);
    }
    if (selectedHour > maxHour) {
      setselectedHour(daysRange[index].maxHour);
    }
    setselectedDayIndex(index);
  }
  function onDayDown() {
    let index = Math.max(selectedDayIndex - 1, 0);
    const maxHour = daysRange[index].maxHour;
    const minHour = daysRange[index].minHour;
    if (selectedHour < minHour) {
      setselectedHour(daysRange[index].minHour);
    }
    if (selectedHour > maxHour) {
      setselectedHour(daysRange[index].maxHour);
    }
    setselectedDayIndex(index);
  }
  function onHourUp() {
    const maxHour = daysRange[selectedDayIndex].maxHour;
    const minHour = daysRange[selectedDayIndex].minHour;
    const newHour = (selectedHour + 1) % 24;
    if (newHour <= maxHour && newHour >= minHour) {
      setselectedHour(newHour);
    }
  }
  function onHourDown() {
    const maxHour = daysRange[selectedDayIndex].maxHour;
    const minHour = daysRange[selectedDayIndex].minHour;
    const newHour = selectedHour - 1 >= 0 ? selectedHour - 1 : 23;
    if (newHour <= maxHour && newHour >= minHour) {
      setselectedHour(newHour);
    }
  }
  function onMinuteUp() {
    setselectedMinute((selectedMinute + 10) % 60);
  }
  function onMinuteDown() {
    setselectedMinute(selectedMinute - 10 >= 0 ? selectedMinute - 10 : 50);
  }
  function twoDigits(number) {
    let num = String(number);
    if (num.length < 2) {
      num = '0' + num;
    }
    return num;
  }
  function getDateDesc(date) {
    return date.toLocaleString(DateTime.DATE_SHORT);
  }
};

export default connect(state => ({
  app: state.app,
}))(DateTimePickerComponent);
