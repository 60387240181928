import {get} from 'lodash';
import React from 'react';
import {connect} from 'react-redux';
import Div from './Div';

const CardComponenet = ({children, style, bottom, width, ...props}) => {
  return (
    <Div
      {...props}
      style={{
        backgroundColor: 'white',
        borderColor: '#dedede',
        borderRadius: 5,
        borderWidth: 1,
        borderStyle: 'solid',
        elevation: 2,
        marginHorizontal: 20,
        marginVertical: 7,
        padding: 20,
        width: width - 40,
        boxShadow: 'rgb(216 216 216) 0px -2px 4px 0px',
        ...(bottom
          ? {
              position: 'fixed',
              bottom: -10,
              zIndex: 5,
              marginHorizontal: 0,
              borderRadius: 15,
              paddingTop: 10,
              paddingBottom: 20,
            }
          : {}),
        ...style,
      }}>
      {children}
    </Div>
  );
};

export default connect(state => ({
  width: get(state, 'window.width', 0),
  height: get(state, 'window.height', 0),
  leftOffset: get(state, 'window.leftOffset', 0),
}))(CardComponenet);
