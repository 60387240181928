import {get} from 'lodash';
import {closeModal, hideLoader, openModal, showLoader} from '../redux/actions';
import Storage from '../common/storage';
import moment from 'moment';
import {ACTIONS, CONSTANTS} from './constants';
import {senderGetAllOffers, senderGetAllPackages} from '../redux/senderActions';
import {
  courierGetAllOffers,
  courierGetAllPackages,
} from '../redux/courierActions';
import Analytics from './analytics';
import {getAppCheckToken, getIdToken, getRealTimeDB} from './firebase';
import {
  addAppStateChangeListener,
  getCurrentAppState,
  getCurrentPosition,
  Platform,
} from './nativeFunctions';
import {nGram} from 'n-gram';
import {getUniqueDviceId} from './getUniqueId';

export const getQueryParams = qs => {
  qs = qs.split('+').join(' ');
  var params = {},
    tokens,
    re = /[?&]?([^=]+)=([^&]*)/g;
  while ((tokens = re.exec(qs))) {
    params[decodeURIComponent(tokens[1])] = decodeURIComponent(tokens[2]);
  }
  return params;
};

export const getGeoLocation = options => {
  return new Promise(resolve => {
    getCurrentPosition(
      location => resolve({success: true, ...location}),
      error => resolve({success: true, error}),
      options,
    );
  });
};

export const saveState = async state => {
  try {
    let stateToSave = {
      lang: get(state, 'lang'),
      chats: get(state, 'chats'),
      shareLink: get(state, 'shareLink'),
      reffredByUser: get(state, 'reffredByUser'),
      receiverApp: {
        confirmedOrders: get(state, 'receiverApp.confirmedOrders'),
        courierRoutes: get(state, 'receiverApp.courierRoutes'),
        offers: get(state, 'receiverApp.offers'),
        payments: get(state, 'receiverApp.payments'),
        hideWarningBeforeAccepting: get(
          state,
          'receiverApp.hideWarningBeforeAccepting',
        ),
      },
      senderApp: {
        offers: get(state, 'senderApp.offers'),
        orders: get(state, 'senderApp.orders'),
      },
      courierLocation: get(state, 'courierLocation', {}),
      serverConstants: get(state, 'serverConstants'),
      user: get(state, 'user'),
      timeStamp: Date.now(),
    };
    await Storage.setItem('@saved-state', JSON.stringify(stateToSave));
  } catch (err) {
    Analytics.reportError(err);
  }
};

function getRelativeTimeObj(lang) {
  return {
    format: (offset, day, type) => {
      if (type === 'day') {
        switch (offset) {
          case 0:
            return lang.today + ' ' + lang.until;
          case 1:
            return lang.tomorrow + ' ' + lang.until;
          default:
            return lang[day] + ' ' + lang.until;
        }
      }
    },
  };
}

export const getReadablePrice = (time, lang) => {
  if (time < Date.now()) {
    return '';
  }
  let now = new Date(Date.now());
  now.setHours(0);
  let dayOffset = Math.floor((time - now) / 86400000);
  let rtf = getRelativeTimeObj(lang);
  return (
    rtf.format(dayOffset, new Date(time).getDay(), 'day') +
    ' ' +
    moment(time).format('HH:mm')
  );
};

export const apiRequest = async (url, data = {}, method = 'GET') => {
  let token = '';
  let fullUrl = url;
  if (Platform.OS !== 'web') {
    fullUrl = 'https://app.bambizz.com' + url;
  }
  try {
    token = await getIdToken();
  } catch (err) {
    //console.log(err);
  }
  const appCheckToken = await getAppCheckToken();
  data.buildNum = CONSTANTS.buildNum;
  data.deviceID = await getUniqueDviceId();
  try {
    let response = await fetch(fullUrl, {
      method: method,
      body: method === 'POST' ? JSON.stringify(data) : undefined,
      headers: {
        authorization: 'Bearer ' + token,
        'content-type': 'application/json',
        'X-Firebase-AppCheck': appCheckToken,
      },
    });
    if (response.ok) {
      return await response.json();
    } else {
      return {
        success: false,
        code: 'NO_CONNECTION',
        message: 'NO_CONNECTION',
      };
    }
  } catch (error) {
    console.log(error);
    return {
      success: false,
      code: 'NO_CONNECTION',
      message: 'NO_CONNECTION',
    };
  }
};

export const showMessage = (
  dispatch,
  title,
  message,
  buttons,
  type = 'GENERAL_MESSAGE',
  warning,
  confirmations,
) => {
  return new Promise(resolutionFunc => {
    const modalConf = {
      title: title,
      text: message,
      warning,
      confirmations,
      type,
      value: undefined,
      onClose: () => {
        dispatch(closeModal());
        resolutionFunc();
      },
      button1: {
        text: get(buttons, '[0]', 'close'),
        onClick: () => {
          dispatch(closeModal());
          resolutionFunc(get(buttons, '[0]', 'close'));
        },
      },
      button2: get(buttons, '[1]', '')
        ? {
            text: get(buttons, '[1]', ''),
            onClick: () => {
              dispatch(closeModal());
              resolutionFunc(get(buttons, '[1]', ''));
            },
          }
        : undefined,
      button3: get(buttons, '[2]', '')
        ? {
            text: get(buttons, '[2]', ''),
            onClick: () => {
              dispatch(closeModal());
              resolutionFunc(get(buttons, '[2]', ''));
            },
          }
        : undefined,
    };
    dispatch(openModal(modalConf));
  });
};

const SENDER_URLS = [
  {page: 'sender-input-order-1', needLogin: false},
  {page: 'sender-login', needLogin: false},
  {page: 'sender-about-us', needLogin: false},
  {page: 'sender-terms-and-conditions', needLogin: false},
  {page: 'sender-contact-us', needLogin: false},
  {page: 'settings', needLogin: false},
  {page: 'sender-order-view', needLogin: true},
  {page: 'sender-payment-page', needLogin: false},
  {page: 'sender-notifications', needLogin: true},
  {page: 'sender-my-orders', needLogin: true},
  {page: 'sender-points-overview', needLogin: true},
  {page: 'sender-billing', needLogin: false},
];

const COURIER_URLS = [
  {page: 'courier-order-around', needLogin: false},
  {page: 'courier-login', needLogin: false},
  {page: 'courier-points-overview', needLogin: true},
  {page: 'courier-about-us', needLogin: false},
  {page: 'courier-terms-and-conditions', needLogin: false},
  {page: 'courier-contact-us', needLogin: false},
  {page: 'courier-my-income', needLogin: false},
  {page: 'courier-my-income-month-view', needLogin: true},
  {page: 'courier-subscribe', needLogin: true},
  {page: 'settings', needLogin: false},
  {page: 'courier-order-view', needLogin: true},
  {page: 'courier-notifications', needLogin: true},
  {page: 'courier-my-orders', needLogin: true},
];

export const getPrices = async (
  fromGoogleId,
  toGoogleId,
  size,
  lang,
  backAndFoth,
) => {
  try {
    let res = await apiRequest(
      '/api_v1/calc-order-price',
      {
        fromPlaceId: fromGoogleId,
        toPlaceId: toGoogleId,
        size,
        lang,
        backAndFoth,
      },
      'POST',
    );
    return {
      prices: res.price || [],
      success: res.success,
      message: res.message,
    };
  } catch (err) {
    Analytics.reportError(err);
    return {
      prices: [],
      success: false,
      message: 'error-getting-prices',
      tryAgain: true,
    };
  }
};

export const getTerms = async lang => {
  let res = await apiRequest(
    '/api_v1/get-terms',
    {
      lang,
    },
    'POST',
  );
  if (res.success) {
    return res.text;
  }
  throw new Error('');
};

export const getAboutUs = async lang => {
  let res = await apiRequest(
    '/api_v1/get-about-us',
    {
      lang,
    },
    'POST',
  );
  if (res.success) {
    return res.text;
  }
  throw new Error('');
};

export const makeApiCall = async (dispatch, getState, options = {}) => {
  try {
    //{url, payload, action, parseRes, callback, retry, showLoader}
    if (options.showLoader) {
      dispatch(showLoader());
    }
    let res = await apiRequest(
      options.url,
      get(options, 'payload', {}) || {},
      'POST',
    );
    if (options.showLoader) {
      dispatch(hideLoader());
    }
    Analytics.reportAPICall(
      options.url,
      get(getState(), 'user.uid', ''),
      res.success,
    );
    if (typeof options.callback === 'function') {
      options.callback(res);
    }
    if (res.success) {
      dispatch({
        type: options.action,
        payload:
          typeof options.parseRes === 'function' ? options.parseRes(res) : null,
      });
    } else {
      if (res.code === 'ORDER_DOES_NOT_EXIST') {
        dispatch(senderGetAllPackages());
        dispatch(courierGetAllPackages());
      }
      if (res.code === 'OFFER_DOES_NOT_EXIST') {
        dispatch(senderGetAllOffers());
        dispatch(courierGetAllOffers());
      }
      if (options.showLoader) {
        showMessage(dispatch, 'error', res.code);
      }
      if (options.retry) {
        dispatch({
          type: ACTIONS.ADD_NETWORK_CALL,
          payload: {
            func: makeApiCall,
            params: [dispatch, getState, options],
          },
        });
      }
    }
  } catch (err) {
    console.log(err);
    if (options.showLoader) {
      dispatch(hideLoader());
      showMessage(dispatch, 'error', 'sorry-we-got-error');
    }
    Analytics.reportError(err);
  }
};

export const noPrice = (fields, price) => {
  return (
    get(fields, 'size', '') === 'custom-package' ||
    get(price, 'paymentsEnabled') === false
  );
};

export const LINK_TYPE = {
  APP_STORE: 'APP_STORE',
  GOOGLE_PLAY: 'GOOGLE_PLAY',
  MY_ORDERS: 'MY_ORDERS',
  SEND_NOW: 'SEND_NOW',
  WEB_APP_QR: 'WEB_APP_QR',
};
export const getDynemicLink = type => {
  const map = {};
  let linkEnd = '';
  if (window.gclid) {
    linkEnd = '?gclid=' + window.gclid;
  }
  switch (type) {
    case LINK_TYPE.APP_STORE:
      map.link = 'https://apps.apple.com/app/id1509656952';
      map.ibi = 'com.bambizz.app';
      map.isi = '1509656952';
      map.utm_campaign = 'App+Store+Button';
      map.utm_medium = 'MAIN_APP_STORE';
      map.utm_source = 'bambizz.com';
      break;
    case LINK_TYPE.GOOGLE_PLAY:
      map.link = 'https://play.google.com/store/apps/details?id=com.bambizz';
      map.apn = 'com.bambizz';
      map.utm_campaign = 'Google+play+buttonn';
      map.utm_medium = 'MAIN_GOOGLE_PLAY';
      map.utm_source = 'bambizz.com';
      break;
    case LINK_TYPE.MY_ORDERS:
      map.link =
        'https://bambizz.com/he/application/sender-my-orders' + linkEnd;
      map.apn = 'com.bambizz';
      map.afl = 'https://app.bambizz.com/sender-my-orders' + linkEnd;
      map.ifl = 'https://app.bambizz.com/sender-my-orders' + linkEnd;
      map.isi = '1509656952';
      map.ibi = 'com.bambizz.app';
      map.st = 'במביז+קהילת+המשלוחים+שלך,+היעילות+היא+בזכותך';
      map.sd =
        'שלח+חבילה+במחיר+הטוב+ביותר+והמתאים+לך,+ובמהירות+שאתה+רוצה,+קבל+מגוון+הצעות+מחיר+ותחליט+מה+הכי+טוב+לך,+אצלנו+אתה+קובע+את+מחיר+המשלוח+שלך,+או+הצטרף+עוד+היום+לקהילת+השליחים+המרווחים+שלנו+ותוכל+להרוויח+כבר+היום+מכל+חבילה+באזור+שלך';
      map.si = 'https://bambizz.com/images/screen_shot_he.png';
      map.utm_campaign = 'Web+app';
      map.utm_medium = 'MY_ORDERS';
      map.utm_source = 'bambizz.com';
      break;
    case LINK_TYPE.SEND_NOW:
      map.link =
        'https://bambizz.com/he/application/sender-input-order-1' + linkEnd;
      map.apn = 'com.bambizz';
      map.afl = 'https://app.bambizz.com/sender-input-order-1' + linkEnd;
      map.ifl = 'https://app.bambizz.com/sender-input-order-1' + linkEnd;
      map.isi = '1509656952';
      map.ibi = 'com.bambizz.app';
      map.st = 'במביז+קהילת+המשלוחים+שלך,+היעילות+היא+בזכותך';
      map.sd =
        'שלח+חבילה+במחיר+הטוב+ביותר+והמתאים+לך,+ובמהירות+שאתה+רוצה,+קבל+מגוון+הצעות+מחיר+ותחליט+מה+הכי+טוב+לך,+אצלנו+אתה+קובע+את+מחיר+המשלוח+שלך,+או+הצטרף+עוד+היום+לקהילת+השליחים+המרווחים+שלנו+ותוכל+להרוויח+כבר+היום+מכל+חבילה+באזור+שלך';
      map.si = 'https://bambizz.com/images/screen_shot_he.png';
      map.utm_campaign = 'Web+app';
      map.utm_medium = 'SEND_NOW';
      map.utm_source = 'bambizz.com';
      break;
    case LINK_TYPE.WEB_APP_QR:
      map.link = 'https://bambizz.com/';
      map.ibi = 'com.bambizz.app';
      map.isi = '1509656952';
      map.apn = 'com.bambizz';
      map.utm_campaign = 'Propt+in+web+app';
      map.utm_medium = 'QR_CODE';
      map.utm_source = 'bambizz.com';
      break;
  }
  if (window.gclid) {
    map.gclid = window.gclid;
  }
  return `https://link.bambizz.com/?${Object.entries(map)
    .map(ent => `${ent[0]}=${encodeURIComponent(ent[1])}`)
    .reduce((a, b) => `${a}&${b}`)}`;
};

export const ngramSimilarity = (input, text, ngramSize = 3) => {
  const ngramFunc = nGram(ngramSize);
  const inputWords = ngramFunc(input.toLowerCase());
  const textWords = ngramFunc(text.toLowerCase());
  return lcs(textWords, inputWords) / inputWords.length || 0;
};

const max = (a, b) => (a > b ? a : b);
export const lcs = (X, Y) => {
  let i,
    j,
    L = [];
  for (i = 0; i <= X.length; i++) {
    L[i] = [];
    for (j = 0; j <= Y.length; j++) {
      L[i].push(Infinity);
    }
  }

  for (i = 0; i <= X.length; i++) {
    for (j = 0; j <= Y.length; j++) {
      if (i === 0 || j === 0) {
        L[i][j] = 0;
      } else if (X[i - 1] === Y[j - 1]) {
        L[i][j] = L[i - 1][j - 1] + 1;
      } else {
        L[i][j] = max(L[i - 1][j], L[i][j - 1]);
      }
    }
  }
  return L[X.length][Y.length];
};

export const addRealtimeDBListener = (path, callback) => {
  const ref = getRealTimeDB().ref(path);

  ref.on('value', snapshot => {
    const val = snapshot.val();
    callback(val);
  });
};

export const removeRealtimeDBListener = (path, callback) => {
  const ref = getRealTimeDB().ref(path);
  ref.off('value', callback);
};

export const onForegroundRealtimeDBListener = (path, callback) => {
  addRealtimeDBListener(path, callback);
  addAppStateChangeListener(() => {
    if (getCurrentAppState() === 'active') {
      addRealtimeDBListener(path, callback);
    } else {
      removeRealtimeDBListener(path, callback);
    }
  });
};
