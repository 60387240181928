import React, {useContext} from 'react';
import {LanguageContext} from '../../language/lang';
import {COLORS} from '../../common/constants';
import {openUrl} from '../../common/nativeFunctions';

export default ({
  children,
  href,
  theme,
  noTranslate,
  style,
  title,
  ...props
}) => {
  const lang = useContext(LanguageContext);
  let color = COLORS.black;
  switch (theme || '') {
    case 'orange':
      color = COLORS.mainColorCourier;
      break;
    case 'purple':
      color = COLORS.mainColorSender;
      break;
    case 'green':
      color = COLORS.covidMainColor;
      break;
  }
  let text = children || '';
  if (!noTranslate && lang[text]) {
    text = lang[text];
  }
  const styles = {
    backgroundColor: color,
    borderRadius: 50,
    padding: 15,
    border: 'none',
    background: 'none',
    wordBreak: 'break-all',
    textAlign: 'center',
    ...style,
  };
  let css = styles;
  for (let key in css) {
    if (typeof css[key] === 'number') {
      css[key] = css[key] + 'px';
    }
  }

  let ariaLabel = lang[title] || title;

  if (!ariaLabel && typeof children === 'string') {
    ariaLabel = children;
  }

  if (!ariaLabel) {
    console.warn(`The following Link is missing aria-label`, children);
  }

  return (
    <a
      {...props}
      aria-label={ariaLabel}
      title={ariaLabel}
      href={href || '#'}
      onClick={() => (href ? openUrl(href) : null)}
      style={css}>
      {children}
    </a>
  );
};
