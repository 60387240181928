import React, {useContext} from 'react';
import {ThemeContext} from '../../themes/theme';
import {LanguageContext} from '../../language/lang';

export default ({src, style, theme, title}) => {
  const themeContext = useContext(ThemeContext);
  const lang = useContext(LanguageContext);

  let styles = {
    ...style,
  };
  (theme || '').split(' ').forEach(t => {
    if (themeContext[t]) {
      styles = {...styles, ...themeContext[t]};
    }
  });
  let css = styles;
  for (let key in css) {
    if (typeof css[key] === 'number') {
      css[key] = css[key] + 'px';
    }
  }
  return (
    <img
      src={src}
      style={css}
      alt={lang[title] || ''}
      role={title ? undefined : 'presentation'}
    />
  );
};
