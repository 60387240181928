import React from 'react';
import * as Icons from './IconsImports';
import {COLORS} from '../common/constants';
import {Div, Span, Image} from './Components';

const {
  Logo,
  Menu,
  MenuWithDot,
  Sender,
  Courier,
  Notifications,
  NoNotifications,
  Close,
  UnChecked,
  Checked,
  Question,
  Form,
  Settings,
  MyOrders,
  PurpleLoader,
  OrangeLoader,
  SendChatMessage,
  SendChatMessageRTL,
  EmptyStar,
  FullStar,
  HalfStar,
  HalfStarRTL,
  Plus,
  PlusBlack,
  Minus,
  Info,
  ReportOrange,
  ReportPurple,
  ArrowLeft,
  ArrowRight,
  ArrowUp,
  ArrowDown,
  FilledSettings,
  Reload,
  Bin,
  Share,
  ToggleOff,
  ToggleOn,
  BackLeft,
  BackRight,
  Filter,
  RadioSelected,
  RadioUnselected,
  RadioUnselectedWhite,
  CustomSize,
  UpTo2KG,
  UpTo5KG,
  UpTo10KG,
  GPSNotFixed,
  GPSFixed,
  Search,
  Edit,
  Google,
  MoreButton,
  LoadingGradient,
  LoadingForCourier,
  History,
  Home,
  Work,
  ExitApp,
  HomeEmpty,
  MasterCard,
  CreditCard,
  Visa,
  Amex,
  Leumi,
  PointsCopper,
  PointsSilver,
  PointsGold,
  Billing,
  ChevronRight,
  ChevronLeft,
  Check,
  ContactUs,
  BambizzPin,
  PackagePin,
  Call,
  Navigation,
  GoogleMaps,
  Waze,
  Barcode,
  Clock,
  Car,
  Truck,
  MotorCycle,
  ColorfulMotorCycle,
  Desktop,
  Bicycle,
  Medal,
  Camera,
  UpdateApp,
  UploadDoc,
  Apple,
  GooglePlay,
  QRCode,
  WhatsApp,
  Map,
  Chats,
  Discounts,
  Location,
  Accessibility,
} = Icons;

const withIconHOC = (Icon, defaultProps) => props => {
  const {width, height, theme, color} = {...defaultProps, ...props};

  let fill = COLORS.black;

  if (theme) {
    fill =
      theme === 'purple' ? COLORS.mainColorSender : COLORS.mainColorCourier;
  }

  if (color) {
    fill = color;
  }

  return (
    <Icon
      width={width || 25}
      height={height || 25}
      fill={fill}
      aria-hidden="true"
    />
  );
};

export const ICSizeIcon = ({width, height, color, size}) => {
  let Size =
    {
      ['2-kg']: ICUpTo2KG,
      ['5-kg']: ICUpTo5KG,
      ['10-kg']: ICUpTo10KG,
      ['custom-package']: ICCustomSize,
    }[size] || null;

  return Size ? <Size width={width} height={height} color={color} /> : null;
};

export const ICLocation = withIconHOC(Location);

export const ICDiscounts = withIconHOC(Discounts);

export const ICQuestionInfo = ({width = 20, height = 20, color = 'grey'}) => (
  <Div
    theme="flex-row jc-center ai-center"
    ariaHidden
    style={{
      borderRadius: 20,
      borderColor: color,
      borderWidth: 2,
      width,
      borderStyle: 'solid',
      height,
      padding: 0,
    }}>
    <Span
      ariaHidden
      style={{
        margin: 0,
        padding: 0,
        lineHeight: height - 4,
        fontSize: height - 6,
        textAlign: 'center',
        fontWeight: 'bold',
        color,
      }}>
      ?
    </Span>
  </Div>
);

export const ICChats = withIconHOC(Chats);

export const ICMap = withIconHOC(Map);

export const ICSendChatMessage = ({width, height, color, isRTL}) => {
  const Icon = withIconHOC(isRTL ? SendChatMessageRTL : SendChatMessage);

  return (
    <Icon
      width={width || 25}
      height={height || 25}
      color={color || COLORS.black}
    />
  );
};

export const ICQRCode = withIconHOC(QRCode);

export const ICWhatsApp = withIconHOC(WhatsApp);

export const ICCamera = withIconHOC(Camera);

export const ICApple = withIconHOC(Apple);

export const ICGooglePlay = withIconHOC(GooglePlay);

export const ICUpdateApp = withIconHOC(UpdateApp);

export const ICUploadDoc = withIconHOC(UploadDoc);

export const ICCheck = withIconHOC(Check);

export const ICMedal = withIconHOC(Medal);

export const ICBicycle = withIconHOC(Bicycle);

export const ICDesktop = withIconHOC(Desktop);

export const ICColorfulMotorCycle = withIconHOC(ColorfulMotorCycle);

export const ICCar = withIconHOC(Car);

export const ICTruck = withIconHOC(Truck);

export const ICMotorCycle = withIconHOC(MotorCycle);

export const ICClock = withIconHOC(Clock);

export const ICBarcode = withIconHOC(Barcode);

export const ICGoogleMaps = withIconHOC(GoogleMaps);

export const ICWaze = withIconHOC(Waze);

export const ICCall = withIconHOC(Call);

export const ICNavigation = withIconHOC(Navigation);

export const ICBambizzPin = withIconHOC(BambizzPin);

export const ICPackagePin = withIconHOC(PackagePin);

export const ICContactUs = withIconHOC(ContactUs);

export const ICChevronRight = withIconHOC(ChevronRight);

export const ICChevronLeft = withIconHOC(ChevronLeft);

export const ICPoints = ({width, height, type}) => {
  const Icon = withIconHOC(
    type === 'gold'
      ? PointsGold
      : type === 'silver'
      ? PointsSilver
      : PointsCopper,
  );

  return <Icon width={width} height={height} />;
};

export const ICCreditCardLogo = ({width, height, issuer}) => {
  let IconElement = CreditCard;
  switch (issuer) {
    case 1:
      IconElement = MasterCard;
      break;
    case 2:
      IconElement = Visa;
      break;
    case 4:
      IconElement = Amex;
      break;
    case 5:
      IconElement = Leumi;
      break;
  }

  const Icon = withIconHOC(IconElement);

  return <Icon width={width} height={height} />;
};

export const ICRadioUnselected = ({width, height, theme}) => {
  const Icon = withIconHOC(
    theme === 'white' ? RadioUnselectedWhite : RadioUnselected,
    {color: '#828282'},
  );

  return <Icon width={width} height={height || 25} />;
};

export const ICGPS = ({width, height, type, color}) => {
  const Icon = withIconHOC(type === 'fixed' ? GPSFixed : GPSNotFixed);

  return <Icon width={width} height={height} color={color} />;
};

export const ICRadioSelected = withIconHOC(RadioSelected);

export const ICFilter = withIconHOC(Filter);

export const ICHomeEmpty = withIconHOC(HomeEmpty);

export const ICBilling = withIconHOC(Billing);

export const ICExitApp = withIconHOC(ExitApp);

export const ICHome = withIconHOC(Home);

export const ICWork = withIconHOC(Work);

export const ICHistory = withIconHOC(History);

export const ICLoadingGradient = withIconHOC(LoadingGradient);

export const ICLoadingForCourier = withIconHOC(LoadingForCourier);

export const ICMoreButton = withIconHOC(MoreButton);

export const ICGoogle = withIconHOC(Google);

export const ICEdit = withIconHOC(Edit);

export const ICMasterCard = withIconHOC(MasterCard);

export const ICSearch = withIconHOC(Search);

export const ICCustomSize = withIconHOC(CustomSize, {color: '#36BBDA66'});

export const ICUpTo2KG = withIconHOC(UpTo2KG, {color: '#36BBDA66'});

export const ICUpTo5KG = withIconHOC(UpTo5KG, {color: '#36BBDA66'});

export const ICUpTo10KG = withIconHOC(UpTo10KG, {color: '#36BBDA66'});

export const ICBackLeft = withIconHOC(BackLeft);

export const ICBackRight = withIconHOC(BackRight);

export const ICShare = withIconHOC(Share);

export const ICToggleOff = withIconHOC(ToggleOff);

export const ICToggleOn = withIconHOC(ToggleOn);

export const ICBin = withIconHOC(Bin, {color: 'grey'});

export const ICReload = withIconHOC(Reload, {width: 30, height: 30});

export const ICFilledSettings = withIconHOC(FilledSettings, {
  width: 30,
  height: 30,
});

export const ICArrowLeft = withIconHOC(ArrowLeft, {width: 30, height: 30});

export const ICArrowRight = withIconHOC(ArrowRight, {width: 30, height: 30});

export const ICArrowUp = withIconHOC(ArrowUp, {width: 30, height: 30});

export const ICArrowDown = withIconHOC(ArrowDown, {width: 30, height: 30});

export const ICSimpleLoader = ({width, height}) => (
  <Image
    title="loading"
    style={{height: height || 40, width: width || 40}}
    source={{
      uri: require('../images/Loader.gif'),
    }}
  />
);

export const ICReport = ({width, height, theme}) => {
  const Icon = withIconHOC(theme === 'orange' ? ReportOrange : ReportPurple);

  return <Icon width={width} height={height} />;
};

export const ICInfo = withIconHOC(Info);

export const ICPlus = ({width, height, theme}) => {
  const Icon = withIconHOC(theme === 'black' ? PlusBlack : Plus);

  return <Icon width={width} height={height} />;
};

export const ICMinus = withIconHOC(Minus);

export const ICFullStar = withIconHOC(FullStar);

export const ICEmptyStar = withIconHOC(EmptyStar);

export const ICHalfStar = ({width, height, direction}) => {
  const Icon = withIconHOC(direction === 'rtl' ? HalfStarRTL : HalfStar);

  return <Icon width={width} height={height} />;
};

export const ICOrangeLoader = withIconHOC(OrangeLoader);

export const ICPurpleLoader = withIconHOC(PurpleLoader);

export const ICMyOrders = withIconHOC(MyOrders);

export const ICQuestion = withIconHOC(Question);

export const ICForm = withIconHOC(Form);

export const ICSettings = withIconHOC(Settings);

export const ICChecked = withIconHOC(Checked);

export const ICUnChecked = withIconHOC(UnChecked);

export const ICClose = withIconHOC(Close);

export const ICMenu = ({width, height, color, dot}) => {
  const Icon = withIconHOC(dot ? MenuWithDot : Menu);

  return <Icon width={width} height={height} color={color} />;
};

export const ICSender = withIconHOC(Sender);

export const ICCourier = withIconHOC(Courier);

export const ICLogo = withIconHOC(Logo);

export const ICNotifications = ({width, height, showDot, color}) => {
  const Icon = withIconHOC(showDot ? Notifications : NoNotifications);

  return <Icon width={width} eight={height} color={color} />;
};

export const ICAccessibility = withIconHOC(Accessibility);
