import React, {useContext} from 'react';
import {get} from 'lodash';
import * as Icons from '../Icons';
import {LanguageContext} from '../../language/lang';
import {COLORS} from '../../common/constants';
import {connect} from 'react-redux';
import Span from './Span';
import {useId} from '../hooks/useId';

export const CheckBoxComponent = ({
  value,
  type,
  onChange,
  noTranslate,
  label,
  style,
  theme = '#000',
  app,
  title,
  ariaHidden,
  labelledBy,
  ...props
}) => {
  const lang = useContext(LanguageContext);
  const labelId = useId();
  let mainColor = '#000';
  if (app === 'sender') {
    mainColor = COLORS.mainColorSender;
  }
  if (app === 'courier') {
    mainColor = COLORS.mainColorCourier;
  }
  let text = label || '';
  if (!noTranslate && lang[text]) {
    text = lang[text];
  }
  let onIcon = <Icons.ICChecked color={COLORS.black} width={35} height={35} />;
  let offIcon = (
    <Icons.ICUnChecked color={COLORS.black} width={35} height={35} />
  );
  switch (type) {
    case 'toggle':
      onIcon = (
        <Icons.ICToggleOn
          color={mainColor}
          theme={theme}
          width={50}
          height={50}
        />
      );
      offIcon = (
        <Icons.ICToggleOff
          color={mainColor}
          theme={theme}
          width={50}
          height={50}
        />
      );
      break;
    case 'radio':
      onIcon = <Icons.ICRadioSelected theme={theme} width={25} height={25} />;
      offIcon = (
        <Icons.ICRadioUnselected theme={theme} width={25} height={25} />
      );
      break;
  }
  const styles = {
    flexDirection: 'row',
    padding: type === 'toggle' ? 10 : 10,
    paddingHorizontal: 0,
    border: 'none',
    background: 'none',
    alignItems: 'center',
    ...(style || {}),
  };
  let css = styles;
  for (let key in css) {
    if (typeof css[key] === 'number') {
      css[key] = css[key] + 'px';
    }
  }
  css.display = 'flex';

  let ariaLabel = lang[title] || title;

  if (!ariaLabel && typeof text === 'string') {
    ariaLabel = text;
  }

  if (!ariaLabel) {
    console.warn('The following CheckBox is missing aria-label', text);
  }

  return (
    <button
      {...props}
      aria-label={!labelledBy && !label ? ariaLabel : undefined}
      aria-labelledby={labelledBy || labelId}
      role="checkbox"
      aria-checked={!!value}
      aria-hidden={ariaHidden}
      tabIndex={ariaHidden ? -1 : 0}
      title={ariaLabel}
      onClick={() => (typeof onChange === 'function' ? onChange(!value) : null)}
      style={css}>
      {value ? onIcon : offIcon}
      {label ? (
        <Span
          id={labelId}
          style={{
            fontSize: '16px',
            whiteSpace: 'nowrap',
            padding: type === 'toggle' ? '12px' : '6px',
          }}>
          {text}
        </Span>
      ) : null}
    </button>
  );
};

const mapStateToProps = state => ({
  app: state.app,
  width: get(state, 'window.width', 0),
});

export default connect(mapStateToProps)(CheckBoxComponent);
