import React, {useContext, useState} from 'react';
import {COLORS} from '../../common/constants';
import {LanguageContext, LANGUAGES} from '../../language/lang';
import Div from './Div';
import Span from './Span';

export default React.forwardRef(
  (
    {style, value, onChange, error, errorMessage, disabled, placeholder},
    ref,
  ) => {
    const lang = useContext(LanguageContext);
    const [, setfocus] = useState(false);
    const styles = {
      container: {
        borderStyle: 'solid',
        flexDirection: lang.id === LANGUAGES.HE ? 'row-reverse' : 'row',
        width: 'calc(100% - 10px)',
        marginTop: 6,
        marginBottom: 6,
        backgroundColor: disabled ? '#f7f6f2' : '#f0f0f0',
        borderColor: error ? 'red' : '#f0f0f0',
        borderRadius: 0,
        borderWidth: 1,
        color: COLORS.black,
        fontSize: 17,
        paddingBottom: 6,
        paddingLeft: 6,
        paddingRight: 6,
        paddingTop: 6,
        lineHeight: 24,
        ...style,
      },
      input: {
        color: COLORS.black,
        fontSize: 17,
        paddingBottom: 7,
        paddingRight: 14,
        paddingTop: 9,
        textAlign: 'left',
        width: 'calc(100% - 20px)',
        border: 'none',
        background: 'none',
      },
      plus: {
        color: '#cccccc',
        fontSize: 17,
        paddingBottom: 7,
        paddingLeft: 14,
        paddingTop: 6,
      },
    };
    return (
      <Div style={styles.container}>
        <Span style={styles.plus}>+</Span>
        <input
          aria-label={lang[placeholder] || placeholder || ''}
          title={lang[placeholder] || placeholder || ''}
          aria-invalid={!!error}
          aria-errormessage={
            error ? lang.defaultErrorMessage || errorMessage : null
          }
          ref={ref}
          type="number"
          onChange={e =>
            typeof onChange === 'function' ? onChange(e.target.value) : null
          }
          value={value}
          onFocus={() => setfocus(true)}
          onBlur={() => setfocus(false)}
          disabled={disabled}
          placeholder={lang[placeholder] || placeholder || ''}
          style={styles.input}
        />
      </Div>
    );
  },
);
