import React from 'react';
import {get} from 'lodash';
import {connect} from 'react-redux';
import mapStyle from '../../themes/mapStyle.json';
import {COLORS} from '../../common/constants';
import {getCountry} from '../../language/lang';

let DEFAULT_LAT = 40.737649013503415,
  DEFAULT_LNG = -74.0021895;

if (getCountry() === 'IL') {
  DEFAULT_LAT = 32.053459;
  DEFAULT_LNG = 34.784366;
}

class BackgroundMapComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isReady: false,
    };
    this.centerMap = this.centerMap.bind(this);
    this.loadGoogleMap = this.loadGoogleMap.bind(this);
    this.onMapLoaded = this.onMapLoaded.bind(this);
    this.mapRef = React.createRef();
  }

  onMapLoaded() {
    if (this.state && !this.state.isReady) {
      this.setState({isReady: true});
    }
  }

  async loadGoogleMap() {
    try {
      let region;
      if (Object.keys(get(this, 'props.locationTwo', {})).length) {
        let latitudeDelta =
          Math.abs(
            get(this, 'props.locationOne.lat', DEFAULT_LAT) -
              get(this, 'props.locationTwo.lat', DEFAULT_LAT),
          ) * 1.8;
        let latitude =
          (get(this, 'props.locationOne.lat', DEFAULT_LAT) +
            get(this, 'props.locationTwo.lat', DEFAULT_LAT)) /
            2 -
          latitudeDelta * 0.14;
        let longitude =
          (get(this, 'props.locationOne.lng', DEFAULT_LNG) +
            get(this, 'props.locationTwo.lng', 0)) /
          2;

        region = {
          lat: latitude,
          lng: longitude,
        };
      } else {
        region = {
          lat: get(this, 'props.locationOne.lat', DEFAULT_LAT) - 0.001,
          lng: get(this, 'props.locationOne.lng', DEFAULT_LNG),
        };
      }
      if (this.props.googleMapLoaded && !this.map) {
        this.directionsService = new window.google.maps.DirectionsService();
        this.directionsRenderer = new window.google.maps.DirectionsRenderer();
        this.map = new window.google.maps.Map(
          document.getElementById('main-map-view'),
          {
            center: region,
            zoom: 16,
            styles: mapStyle,
            fullscreenControl: false,
            mapTypeControl: false,
            panControl: false,
            rotateControl: false,
            streetViewControl: false,
            zoomControl: false,
            disableDefaultUI: true,
            //disableDoubleClickZoom:false,
            // gestureHandling: "none",
          },
        );
        window.google.maps.event.addListenerOnce(
          this.map,
          'idle',
          this.onMapLoaded,
        );
        this.directionsRenderer.setMap(this.map);
      } else if (this.map) {
        this.map.setCenter(region);
      }
      if (!this.markerOne) {
        this.markerOne = new window.google.maps.Marker({
          position: {
            lat: get(this, 'props.locationOne.lat', DEFAULT_LAT) - 0.001,
            lng: get(this, 'props.locationOne.lng', DEFAULT_LNG),
          },
          icon: '/images/BambizzPin.png',
          map: this.map,
        });
      } else {
        this.markerOne.setPosition({
          lat: get(this, 'props.locationOne.lat', DEFAULT_LAT) - 0.001,
          lng: get(this, 'props.locationOne.lng', DEFAULT_LNG),
        });
      }
      if (!get(this, 'props.locationTwo.lat')) {
        if (this.markerTwo) {
          this.markerTwo.setMap(null);
        }
      } else if (!this.markerTwo) {
        this.markerTwo = new window.google.maps.Marker({
          position: {
            lat: get(this, 'props.locationTwo.lat', DEFAULT_LAT) - 0.001,
            lng: get(this, 'props.locationTwo.lng', DEFAULT_LNG),
          },
          map: this.map,
          icon: '/images/BambizzPin.png',
        });
      } else {
        this.markerTwo.setPosition({
          lat: get(this, 'props.locationTwo.lat', DEFAULT_LAT) - 0.001,
          lng: get(this, 'props.locationTwo.lng', DEFAULT_LNG),
        });
        this.markerTwo.setMap(this.map);
      }
      if (
        get(this, 'props.locationTwo.lat') &&
        get(this, 'props.locationOne.lat')
      ) {
        this.directionsService.route(
          {
            origin: {
              lat: get(this, 'props.locationOne.lat', DEFAULT_LAT) - 0.001,
              lng: get(this, 'props.locationOne.lng', DEFAULT_LNG),
            },
            destination: {
              lat: get(this, 'props.locationTwo.lat', DEFAULT_LAT) - 0.001,
              lng: get(this, 'props.locationTwo.lng', DEFAULT_LNG),
            },
            travelMode: window.google.maps.TravelMode.DRIVING,
          },
          (response, status) => {
            if (status === 'OK') {
              this.directionsRenderer.setDirections(response);
              this.directionsRenderer.setOptions({
                markerOptions: {icon: '/images/BambizzPin.png'},
                polylineOptions: {
                  strokeColor: COLORS.mainColorSender,
                  strokeWeight: 4,
                },
              });
            }
          },
        );
        this.map.panToBounds(
          [
            {
              lat: get(this, 'props.locationOne.lat', DEFAULT_LAT) - 0.001,
              lng: get(this, 'props.locationOne.lng', DEFAULT_LNG),
            },
            {
              lat: get(this, 'props.locationTwo.lat', DEFAULT_LAT) - 0.001,
              lng: get(this, 'props.locationTwo.lng', DEFAULT_LNG),
            },
          ],
          10,
        );
      }
    } catch (err) {
      console.log(err);
    }
  }

  componentDidMount() {
    this.loadGoogleMap();
  }

  componentDidUpdate() {
    this.loadGoogleMap();
  }

  centerMap() {
    try {
      let region;
      if (Object.keys(get(this, 'props.locationTwo', {})).length) {
        let latitudeDelta =
          Math.abs(
            get(this, 'props.locationOne.lat', DEFAULT_LAT) -
              get(this, 'props.locationTwo.lat', DEFAULT_LAT),
          ) * 1.8;
        let latitude =
          (get(this, 'props.locationOne.lat', DEFAULT_LAT) +
            get(this, 'props.locationTwo.lat', DEFAULT_LAT)) /
            2 -
          latitudeDelta * 0.14;
        let longitude =
          (get(this, 'props.locationOne.lng', DEFAULT_LNG) +
            get(this, 'props.locationTwo.lng', 0)) /
          2;
        region = {
          lat: latitude,
          lng: longitude,
        };
      } else {
        region = {
          lat: get(this, 'props.locationOne.lat', DEFAULT_LAT) - 0.001,
          lng: get(this, 'props.locationOne.lng', DEFAULT_LNG),
        };
      }
      this.map.setCenter(region);
    } catch (e) {
      console.log(e);
    }
  }

  render() {
    const mapHeight = this.state.isReady ? this.props.height || 1 : 1;
    return (
      <div
        style={{height: '100vh', backgroundColor: '#f5f5f5'}}
        role="presentation"
        tabIndex={-1}>
        <div
          style={{
            width: (this.props.width || 1) + 'px',
            height: mapHeight - (this.props.bottomOffset || 0) + 'px',
          }}
          id="main-map-view"
        />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    googleMapLoaded: state.googleMapLoaded,
  };
};

export default connect(mapStateToProps)(BackgroundMapComponent);
