import {useEffect} from 'react';
import {Platform} from '../../common/nativeFunctions';

export const useOnEsc = ({onEscPressed}) => {
  useEffect(() => {
    function handleKeyDown(e) {
      if (e.keyCode === 27) {
        onEscPressed?.();
      }
    }

    if (Platform.OS === 'web') {
      document.addEventListener('keydown', handleKeyDown);
    }
    return () => {
      if (Platform.OS === 'web') {
        document.removeEventListener('keydown', handleKeyDown);
      }
    };
  }, []);
};
