import {initializeApp} from 'firebase/app';
import {get} from 'lodash';
import {
  getAuth,
  RecaptchaVerifier,
  signInWithCustomToken as FBSignInWithCustomToken,
  signInWithPhoneNumber as FBSignInWithPhoneNumber,
} from 'firebase/auth';
import {get as dbGet, getDatabase, onValue, ref, set} from 'firebase/database';
import {getAnalytics} from 'firebase/analytics';
import {
  initializeAppCheck,
  ReCaptchaEnterpriseProvider,
  getToken,
} from 'firebase/app-check';

const firebaseConfig = {
  apiKey: 'AIzaSyD6MN-XvDcdBMFiZhsdrM4Rj5xht0MpkDE',
  authDomain: 'bambizz.firebaseapp.com',
  databaseURL: 'https://bambizz.firebaseio.com',
  projectId: 'bambizz',
  storageBucket: 'bambizz.appspot.com',
  messagingSenderId: '254590358355',
  appId: '1:254590358355:web:2a32494748a0e663ce07a6',
  measurementId: 'G-PGTEBBJNVT',
};

window.firebaseConfig = firebaseConfig;

const app = initializeApp(firebaseConfig);
const database = getDatabase(app);
export const analytics = getAnalytics();
window.appCheck = initializeAppCheck(app, {
  provider: new ReCaptchaEnterpriseProvider(
    '6LdbRgsqAAAAAE9y3KMjoemZ3HbDfgNQtI28Bw-6',
  ),
  isTokenAutoRefreshEnabled: true,
});

const auth = getAuth();

export const getAppCheckToken = async forceRefresh => {
  try {
    const {token} = await getToken(window.appCheck, forceRefresh);

    if (token.length > 0) {
      return token;
    }
  } catch (error) {
    console.log('AppCheck verification failed', error);
  }

  return null;
};

export const getIdToken = async () => await auth.currentUser.getIdToken();

class RealTimeDBRef {
  ref;

  constructor(ref) {
    this.ref = ref;
  }

  set(value) {
    return set(this.ref, value);
  }

  once() {
    return dbGet(this.ref);
  }

  on(type, callback) {
    onValue(this.ref, callback);
  }
}

class RealTimeDB {
  database;
  constructor(database) {
    this.database = database;
  }
  ref(path) {
    const reference = ref(this.database, path);
    return new RealTimeDBRef(reference);
  }
}

export const getRealTimeDB = () => new RealTimeDB(database);

export const registerDeviceForFCM = () => null;

export const getCurrentFCMToken = () => '';

export const onFCMTokenRefresh = () => null;

export const onAuthStateChanged = func => auth.onAuthStateChanged(func);

export const setAuthForIOSEmulator = () => null;

export const signInWithPhoneNumber = async phoneNumber => {
  try {
    let element = document.getElementById('hide-recaptcha');
    element.innerText = '';
    try {
      await window.recaptchaVerifier.verify();
    } catch (err) {
      console.error(err);
      await window.recaptchaVerifier.reset();
      await window.recaptchaVerifier.verify();
    }
    let confirmationResult = await FBSignInWithPhoneNumber(
      auth,
      phoneNumber,
      window.recaptchaVerifier,
    );
    try {
      window.recaptchaVerifier.clear();
      delete window.recaptchaVerifier;
    } catch (err) {
      console.log(err);
    }
    element.innerText = '.grecaptcha-badge{visibility: hidden;}';
    return confirmationResult;
  } catch (err) {
    console.log(err);
    let element = document.getElementById('hide-recaptcha');
    element.innerText = '.grecaptcha-badge{visibility: hidden;}';
    await window.recaptchaVerifier.render().then(widgetId => {
      window.grecaptcha.reset(widgetId);
    });
    throw err;
  }
};

export const signInWithCustomToken = async token => {
  let userCredential = await FBSignInWithCustomToken(auth, token);
  return userCredential.user;
};

export const confirmAuthSMSCode = async (code, verification) => {
  let result = await verification.confirm(code);
  return get(result, 'user', {});
};

export const signOut = async () => await auth.signOut();

export const initializeRecapcha = () => {
  window.recaptchaVerifier = new RecaptchaVerifier(auth, 'hidden-captcha', {
    size: 'invisible',
  });
};
